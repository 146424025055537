import { useEffect, useState } from 'react';
import { CloseButton, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { acquireRecurringSecurity, deleteRecurringSecurity, updateRecurringSecurity } from '../../../services/securitiesService';


const EditRecurringSecurityModal = ({ selectedRecurringSecurityId, selectedRecurringSecurityData, setSelectedRecurringSecurityData, show, onHide }) => {

    useEffect(() => {

        acquireRecurringSecurity(selectedRecurringSecurityId, setSelectedRecurringSecurityData)

    }, [selectedRecurringSecurityId])



    const handleSecurityChange = (e) => {

        setSelectedRecurringSecurityData(state => ({ ...state, [e.target.name]: e.target.value }))
    }




    const handleUpdateRecurringSecuritySubmit = (e) => {

        e.preventDefault();

        updateRecurringSecurity(selectedRecurringSecurityId, selectedRecurringSecurityData)

        onHide();
    }



    const handleDeleteRecurringSecurity = (e) => {

        e.preventDefault();

        deleteRecurringSecurity(selectedRecurringSecurityId, selectedRecurringSecurityData)

        onHide();
    }


    const recurringSecurityModalClose = () => {

        onHide();
    }

    return (

        <Modal
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редакция на данни: {selectedRecurringSecurityData.name}
                </Modal.Title>
                <CloseButton onClick={recurringSecurityModalClose} />
            </Modal.Header>
            <Modal.Body>
                <div className='security-entries-container'>
                    <div className='security-entry-item'>
                        <Form.Group className="mb-3" controlId="payment-value" >
                            <Form.Label>Име:</Form.Label>
                            <Form.Control name="name" type="text" value={selectedRecurringSecurityData.name} onChange={handleSecurityChange}></Form.Control>
                        </Form.Group>
                    </div>
                    <div className='security-entry-item'>
                        <Form.Group className="mb-3" controlId="payment-value" >
                            <Form.Label>Осигурителна вноска BGN:</Form.Label>
                            <Form.Control name="value" type="number" value={selectedRecurringSecurityData.value} onChange={handleSecurityChange}></Form.Control>
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <Button onClick={handleDeleteRecurringSecurity} variant="danger">Изтриване на записа</Button>
                </div>
                <div>
                    <Button onClick={handleUpdateRecurringSecuritySubmit} variant="warning">Запис</Button>
                    <Button onClick={recurringSecurityModalClose} variant="secondary">Затвори</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default EditRecurringSecurityModal;