import { useEffect, useState } from "react";

import { Accordion, Form, Table } from "react-bootstrap";
import { updateSettlementsCovered } from "../../services/settlementService";


function Settlement({ settlement, stateListener, setStateListener }) {

    const initialOutstanding = {
        firstSide: {
            brokerRewardCovered: '',
            externalRewardCovered: '',
        },
        secondSide: {
            brokerRewardCovered: '',
            externalRewardCovered: ''
        },
        bankAssistance: {
            brokerRewardCovered: '',
            externalRewardCovered: '',
        },
    }

    const [outstandingState, setOutstandingState] = useState(initialOutstanding);




    useEffect(() => {

        setOutstandingState(state => ({
            ...state, firstSide: (
                { ...state.firstSide, brokerRewardCovered: settlement.data.firstSide.brokerRewardCovered }
            )
        }))
        setOutstandingState(state => ({
            ...state, firstSide: (
                { ...state.firstSide, externalRewardCovered: settlement.data.firstSide.externalRewardCovered }
            )
        }))

        setOutstandingState(state => ({
            ...state, secondSide: (
                { ...state.secondSide, brokerRewardCovered: settlement.data.secondSide.brokerRewardCovered }
            )
        }))
        setOutstandingState(state => ({
            ...state, secondSide: (
                { ...state.secondSide, externalRewardCovered: settlement.data.secondSide.externalRewardCovered }
            )
        }))

        setOutstandingState(state => ({
            ...state, bankAssistance: (
                { ...state.bankAssistance, brokerRewardCovered: settlement.data.bankAssistance.brokerRewardCovered }
            )
        }))
        setOutstandingState(state => ({
            ...state, bankAssistance: (
                { ...state.bankAssistance, externalRewardCovered: settlement.data.bankAssistance.externalRewardCovered }
            )
        }))
    }, [settlement])



    const handleChange = (e) => {

        // State listener to rerender parent component
        setStateListener(!stateListener)

        const settlementId = e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.id;
        const dealSide = e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.id;
        const updatedKey = e.target.name;
        const updatedValue = e.target.value;


        setOutstandingState(state => ({ ...state, [dealSide]: ({ ...state[dealSide], [e.target.name]: e.target.value }) }))

        updateSettlementsCovered(settlementId, dealSide, updatedKey, updatedValue)

    }

    return (
        <div id={settlement.id} className="settlement-container">
            {settlement.data.firstSide && (
                <>
                    <div className="settlement-title-container"><h6>Сделка № : {(settlement.data.secondSide.offerNo) ?
                        `${settlement.data.firstSide.offerNo}.${settlement.data.secondSide.offerNo}`
                        : settlement.data.firstSide.offerNo}</h6></div>


                    <div id={'firstSide'} className="settlement-first-side-container">
                        <Table striped bordered>
                            <thead>
                                <tr key={1}>
                                    <th colSpan={'100%'}>Брокер за страна {settlement.data.firstSide.offerNo}:</th>
                                </tr>
                                <tr key={2} >
                                    <td colSpan={'100%'}> {settlement.data.firstSide.broker}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key={3}>
                                    <td>Възнаграждение:</td>
                                    <td>{settlement.data.firstSide.brokerReward} лв.</td>
                                </tr>
                                <tr key={4}>
                                    <td>Изплатени:</td>
                                    <td>
                                        <Form.Control name="brokerRewardCovered" type="number" placeholder='Изплатени'
                                            value={outstandingState.firstSide.brokerRewardCovered} onChange={handleChange} />
                                    </td>
                                </tr>
                                <tr key={5}>
                                    <td>Стоящи:</td>
                                    <td>
                                        {(settlement.data.firstSide.brokerReward - outstandingState.firstSide.brokerRewardCovered).toFixed(2)} лв.
                                    </td>
                                </tr>
                                <tr key={6}>
                                    <td>Агентски:</td>
                                    <td>{settlement.data.firstSide.externalReward} лв.</td>
                                </tr>
                                <tr key={7}>
                                    <td>Изплатени:</td>
                                    <td>
                                        <Form.Control name="externalRewardCovered" type="number" placeholder='Изплатени'
                                            value={outstandingState.firstSide.externalRewardCovered} onChange={handleChange} />
                                    </td>
                                </tr>
                                <tr key={8}>
                                    <td>Стоящи:</td>
                                    <td>
                                        {(settlement.data.firstSide.externalReward - outstandingState.firstSide.externalRewardCovered).toFixed(2)} лв.
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </>
            )
            }

            {
                settlement.data.secondSide && (
                    <>
                        <div id={'secondSide'} className="settlement-second-side-container">
                            <Table striped bordered>
                                <thead>
                                    <tr key={1}>
                                        <th colSpan={'100%'}>Брокер за страна {settlement.data.secondSide.offerNo}:</th>
                                    </tr>
                                    <tr key={2} >
                                        <td colSpan={'100%'}> {settlement.data.secondSide.broker}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={3}>
                                        <td>Възнаграждение:</td>
                                        <td>{settlement.data.secondSide.brokerReward} лв.</td>
                                    </tr>
                                    <tr key={4}>
                                        <td>Изплатени:</td>
                                        <td>
                                            <Form.Control name="brokerRewardCovered" type="number" placeholder='Изплатени'
                                                value={outstandingState.secondSide.brokerRewardCovered} onChange={handleChange} />
                                        </td>
                                    </tr>
                                    <tr key={5}>
                                        <td>Стоящи:</td>
                                        <td>
                                            {(settlement.data.secondSide.brokerReward - outstandingState.secondSide.brokerRewardCovered).toFixed(2)} лв.
                                        </td>
                                    </tr>

                                    <tr key={6}>
                                        <td>Агентски:</td>
                                        <td>{settlement.data.secondSide.externalReward} лв.</td>
                                    </tr>
                                    <tr key={7}>
                                        <td>Изплатени:</td>
                                        <td>
                                            <Form.Control name="externalRewardCovered" type="number" placeholder='Изплатени'
                                                value={outstandingState.secondSide.externalRewardCovered} onChange={handleChange} />
                                        </td>
                                    </tr>
                                    <tr key={8}>
                                        <td>Стоящи:</td>
                                        <td>
                                            {(settlement.data.secondSide.externalReward - outstandingState.secondSide.externalRewardCovered).toFixed(2)} лв.
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </>
                )
            }

            {settlement.data.bankAssistance && (
                <div id={'bankAssistance'} className="settlement-bank-side-container">
                    < Table>
                        <tbody>
                            <tr key={1}>
                                <td>Кредитен посредник:</td>
                                <td>{settlement.data.bankAssistance.broker}</td>
                            </tr>
                            <tr key={2}>
                                <td>Възнаграждение:</td>
                                <td>{settlement.data.bankAssistance.brokerReward} лв.</td>
                            </tr>
                            <tr key={3}>
                                <td>Изплатени:</td>
                                <td> <Form.Control name="brokerRewardCovered" type="number" placeholder='Изплатени'
                                    value={outstandingState.bankAssistance.brokerRewardCovered} onChange={handleChange} />
                                </td>
                            </tr>
                            <tr key={4}>
                                <td>Стоящи:</td>
                                <td>
                                    {(settlement.data.bankAssistance.brokerReward - outstandingState.bankAssistance.brokerRewardCovered).toFixed(2)} лв.
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            )
            }


            {/* <div className="settlement-item">
                        Агентски:
                    </div>
                    <div className="settlement-item" readOnly={true}>
                        {settlement.data.bankAssistance.externalReward}
                    </div>
                    <div className="settlement-item">
                        Платени:
                    </div>
                    <div className="settlement-item">
                        <Form.Control name="externalRewardCovered" type="number" placeholder='Изплатени'
                            value={outstandingState.bankAssistance.externalRewardCovered} onChange={handleChange} />
                    </div>
                    <div className="settlement-item">
                        Остатък:
                    </div>
                    <div className="settlement-item">
                        <Form.Control name="externalRewardOutstanding" type="number" placeholder='Изплатени'
                            value={settlement.data.bankAssistance.externalReward - outstandingState.bankAssistance.externalRewardCovered}
                            readOnly={true} />
                    </div> */}


        </div >
    )
}

export default Settlement;