import { useEffect, useState } from 'react';
import { CloseButton, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { acquireSelectedExpenditure, deleteSelectedExpenditure, updateSelectedExpenditure } from '../../services/expenditureService';


const EditExpenditureModal = ({ selectedExpenditureId, selectedExpenditureData, setSelectedExpenditureData, show, onHide }) => {

    useEffect(() => {

        acquireSelectedExpenditure(selectedExpenditureId, setSelectedExpenditureData)

    }, [selectedExpenditureId])




    const handleExpenditureChange = (e) => {

        setSelectedExpenditureData(state => ({ ...state, [e.target.name]: e.target.value }))
    }




    const handleUpdateSelectedSubmit = (e) => {

        e.preventDefault();

        updateSelectedExpenditure(selectedExpenditureId, selectedExpenditureData)

        onHide();
    }



    const editExpenditureModalClose = () => {

        onHide();
    }


    const handleDeleteSelected = (e) => {

        e.preventDefault();

        deleteSelectedExpenditure(selectedExpenditureId)

        onHide();
    }

    return (
        <Modal
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редакция на разход: {selectedExpenditureData.name}
                </Modal.Title>
                <CloseButton onClick={editExpenditureModalClose} />
            </Modal.Header>
            {selectedExpenditureData && (<Modal.Body>
                <div className='payment-entries-container'>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Наименование:</Form.Label>
                            <Form.Control name="name" type="text" value={selectedExpenditureData.name} onChange={handleExpenditureChange}></Form.Control>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Фактуриране</Form.Label>
                            <Form.Select name="invoice" value={selectedExpenditureData.invoice} onChange={handleExpenditureChange}>
                                {/* <option key={1} value={''}>Фактуриране</option> */}
                                <option key={2} value={'true'}>Фактура</option>
                                <option key={3} value={'false'}>Нефактуриран</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-3" controlId="payment-method">
                            <Form.Label>Начин на плащане</Form.Label>
                            <Form.Select name="method" value={selectedExpenditureData.method} onChange={handleExpenditureChange}>
                                {/* <option key={1} disabled={true} hidden={true} value={''}>Посочете начин на плащане</option> */}
                                <option key={2} value={'cash'}>Брой</option>
                                <option key={3} value={'wire'}>Банков превод</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Размер без ДДС:</Form.Label>
                            <Form.Control name="value" type="number" value={selectedExpenditureData.value} onChange={handleExpenditureChange}></Form.Control>
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>)}
            <Modal.Footer>
                <div>
                    <Button onClick={handleDeleteSelected} variant="danger">Изтриване на разхода</Button>
                </div>
                <div>
                    <Button onClick={handleUpdateSelectedSubmit} variant="warning">Запис</Button>
                    <Button onClick={editExpenditureModalClose} variant="secondary">Затвори</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )

}

export default EditExpenditureModal;