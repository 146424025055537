import Login from './Login';

const Frontpage = function () {

    return (
        < div >
            <Login />
        </div >
    );
};

export default Frontpage;