import { useEffect, useState } from "react";
import { Nav, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import { monthsBG, monthsEN } from "../common/monthNames";

function MonthSelector() {

    const [currentDirectory, setCurrentDirectory] = useState('');

    useEffect(() => {
        setCurrentDirectory(window.location.pathname);
    }, [])


    return (
        <Nav variant="pills" defaultActiveKey="/home">
            {monthsEN.map((month, index) => {
                return (
                    <Nav.Item key={index}>
                        <NavLink as={Link} to={`${currentDirectory}/${month}`} className={({ isActive }) => (isActive ? 'active' : '')} > {monthsBG[index]}</NavLink >
                    </Nav.Item>
                )

            })}
        </Nav>
    )
}

export default MonthSelector;




