import { db } from "../firebase-config"



// Create Monthly Expenditures
export function createMonthlyExpenditures(selectedMonth, annum, recurringExpenditures) {

    recurringExpenditures.forEach(expenditure => {

        db.collection('expenditures').add(expenditure.data)
            .then(expenditure => {
                db.collection('expenditures').doc(expenditure.id).update({
                    'year': annum,
                    'month': selectedMonth
                })
            })
            .catch(error => {
                console.log(error)
            });
    })
}



// Acquire All Expenditures
export async function acquireAllExpenditures(setMonthlyExpenditures) {
    db.collection('expenditures').onSnapshot(snapshot => {

        const allExpenditures = []
        snapshot.forEach(expenditure => {

            const currentExpenditure = {
                id: expenditure.id,
                data: expenditure.data()
            }
            allExpenditures.push(currentExpenditure)
        })
        setMonthlyExpenditures(allExpenditures)
    })
}




// Acquire Monthly Expenditures for a given year
export async function acquireMonthlyExpendituresProAnnum(annum, selectedMonth, setMonthlyExpendituresCollection) {
    if (annum && selectedMonth && setMonthlyExpendituresCollection) {

        db.collection('expenditures').where('month', '==', selectedMonth).onSnapshot(snapshot => {

            const currentMonthlyExpenditures = []
            snapshot.forEach(expenditure => {

                if (expenditure.data().year == annum) {
                    const currentExpenditure = {
                        id: expenditure.id,
                        data: expenditure.data()
                    }
                    currentMonthlyExpenditures.push(currentExpenditure)
                }
            })
            setMonthlyExpendituresCollection(currentMonthlyExpenditures)
        })
    }
}





export async function createNewExpenditure(newExpenditure, annum, selectedMonth, setLoading) {
    setLoading(true)

    db.collection('expenditures').add(newExpenditure).then(expenditure => {
        db.collection('expenditures').doc(expenditure.id).update({
            'year': annum,
            'month': selectedMonth
        })
        setLoading(false)
    }).catch(error => {
        console.log(error)
        setLoading(false)
    });
}



export async function acquireSelectedExpenditure(selectedExpenditureId, setSelectedExpenditureData) {
    db.collection('expenditures').doc(selectedExpenditureId).onSnapshot(expenditure => {
        setSelectedExpenditureData(expenditure.data())
    })
}


export async function updateSelectedExpenditure(selectedExpenditureId, selectedExpenditureData) {

    await db.collection('expenditures').doc(selectedExpenditureId).set(selectedExpenditureData)
}



export async function updateSingleExpenditureValue(selectedExpenditureId, updatedKey, updatedValue, selectedMonth) {

    if (updatedKey === 'invoiceNo') {
        await db.collection('expenditures').doc(selectedExpenditureId).update({
            'invoiceNo': updatedValue
        })
    } else if (updatedKey === 'covered') {
        await db.collection('expenditures').doc(selectedExpenditureId).update({
            'covered': updatedValue
        })
    }

}


export async function deleteSelectedExpenditure(selectedExpenditureId) {

    await db.collection('expenditures').doc(selectedExpenditureId).delete()
}





// Administration Reward
export async function createAdministrationExpenditure(initialExpenditure, selectedMonthOrderNumber) {
    await db.collection('expenditures').doc(`AdministrationReward-${selectedMonthOrderNumber}`).get().then(administrationExpenditure => {
        if (!administrationExpenditure.data()) {
            db.collection('expenditures').doc(`AdministrationReward-${selectedMonthOrderNumber}`).set(initialExpenditure)
        }
    })
}

// If statement placed to avoid error if the administration expenditure has not been created before
export async function updateAdministrationExpenditure(administrationReward, selectedMonthOrderNumber) {
    await db.collection('expenditures').doc(`AdministrationReward-${selectedMonthOrderNumber}`).update({
        'value': administrationReward
    }).catch(error => {
        console.log(error)

    });
}

export async function updateAdministrationExpenditureCovered(administrationRewardCovered, selectedMonthOrderNumber) {
    await db.collection('expenditures').doc(`AdministrationReward-${selectedMonthOrderNumber}`).update({
        'covered': administrationRewardCovered
    })
}

// If statement placed to avoid error if the administration expenditure has not been created before
export async function acquireAdministrationRewardCovered(selectedMonthOrderNumber, setAdministrationRewardCovered) {
    await db.collection('expenditures').doc(`AdministrationReward-${selectedMonthOrderNumber}`).get().then(administrationReward => {
        if (administrationReward.data()) {
            setAdministrationRewardCovered(administrationReward.data().covered)
        }
    })
}


/////////////////////////////////////////////////////////////////////////////////
//                                                                             //
//                          RECURRING EXPENDITURES                             //
//                                                                             //
/////////////////////////////////////////////////////////////////////////////////

// Recurring Expenditures
export async function createNewRecurringExpenditure(recurringExpenditure) {
    await db.collection('recurring').add(recurringExpenditure)
        .then(newRecurringExpenditure => {
            //          
        })
        .catch(error => {
            console.log(error)
        });
}


export async function updateRecurringExpenditure(selectedRecurringExpenditureId, selectedRecurringExpenditureData) {
    await db.collection('recurring').doc(selectedRecurringExpenditureId).set(selectedRecurringExpenditureData)
}



export async function acquireRecurringExpenditure(selectedRecurringExpenditureId, setSelectedRecurringExpenditureData) {
    const currentSelectedExpenditure = await db.collection('recurring').doc(selectedRecurringExpenditureId).get();

    setSelectedRecurringExpenditureData(currentSelectedExpenditure.data())
}



export async function acquireAllRecurringExpenditures(setRecurringExpendituresCollection) {
    db.collection('recurring').onSnapshot(recurringExpenditures => {
        const currentRecurringExpenditures = []
        recurringExpenditures.forEach(recurringExpenditure => {
            const expenditure = {
                id: recurringExpenditure.id,
                data: recurringExpenditure.data()
            }
            currentRecurringExpenditures.push(expenditure)
        })
        setRecurringExpendituresCollection(currentRecurringExpenditures)
    })
}


export async function deleteRecurringExpenditure(selectedRecurringExpenditureId) {

    await db.collection('recurring').doc(selectedRecurringExpenditureId).delete()
}