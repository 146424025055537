import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";

import { SingleSecurity } from "./SingleSecurity";
import { useNavigate, useParams } from "react-router";
import { useAnnum } from "../../contexts/AnnualContext";
import MonthSelector from "../MonthSelector";
import { monthsBG, monthsEN } from "../../common/monthNames";
import { acquireAllRecurringSecurities, acquireMonthlySecuritiesProAnnum, createMonthlySecurities } from "../../services/securitiesService";
import NewSecurityModal from "./NewSecurity";
import EditSecurityModal from "./EditSecurity";




function Securities() {

    const params = useParams();
    const navigate = useNavigate();
    const { annum } = useAnnum()


    const [recurringSecuritiesCollection, setRecurringSecuritiesCollection] = useState('')
    const [selectedMonth, setSelectedMonth] = useState('')
    const [titleMonth, setTitleMonth] = useState('')
    const [monthlySecurities, setMonthlySecurities] = useState('')

    const [showEditSecurityModal, setShowEditSecurityModal] = useState(false)
    const [selectedSecurityId, setSelectedSecurityId] = useState('')
    const [selectedSecurityData, setSelectedSecurityData] = useState('')

    const [showNewSecurityModal, setShowNewSecurityModal] = useState(false)

    // Initialize Recurring Securities
    useEffect(() => {
        acquireAllRecurringSecurities(setRecurringSecuritiesCollection)
    }, [])

    // Set Selected Month and Acquire the Corresponding Securities List
    useEffect(() => {
        setSelectedMonth(params.month)
        const monthIndex = monthsEN.indexOf(params.month)
        setTitleMonth(monthsBG[monthIndex])
    }, [params])

    useEffect(() => {
        setMonthlySecurities('')
        if (!selectedMonth) {
            setSelectedMonth('january')
            navigate(`/securities/january`)
        }
        acquireMonthlySecuritiesProAnnum(annum, selectedMonth, setMonthlySecurities)
    }, [selectedMonth, annum])

    // Initialize Initial Securities and convert them to monthly expenditures of for a given year
    const handleGenerateRecurrentClick = () => {

        acquireAllRecurringSecurities(setRecurringSecuritiesCollection)

        createMonthlySecurities(selectedMonth, annum, recurringSecuritiesCollection,)
    }


    const handleEditSecurityClick = (e) => {
        setSelectedSecurityId(e.currentTarget.id)
        console.log('fire')
        if (e.target.id == "name") {
            setShowEditSecurityModal(true)
        }

    }


    const handleCreateSecurityClick = () => {

        setShowNewSecurityModal(true)

    }

    const handleAccessRecurringSecuritiesClick = () => {

        navigate('/securities-recurring')

    }


    return (
        <>
            <div>
                <div className="month-selector">
                    <MonthSelector />
                </div>
                {selectedMonth && (
                    <div className="month-header">
                        <h5 className="header-title">Осигуровки: - {annum} - {titleMonth}</h5>
                        <div className='header-button-container'>
                            {!monthlySecurities.length > 0 && (
                                <>
                                    <Button className='header-button' onClick={handleGenerateRecurrentClick}>
                                        Зареди месечния списък с лични осигуровки
                                    </Button>
                                    <Button className='header-button' onClick={handleAccessRecurringSecuritiesClick}>
                                        Редактирай периодичните осигуровки
                                    </Button>
                                </>
                            )}
                        </div>
                        <hr className="header-line"></hr><hr className="header-line"></hr>
                    </div>
                )}
                {monthlySecurities.length > 0 && (
                    <div className="expenditure-container">
                        <Table striped hover variant="light" className="expenditure-table">
                            <thead>
                                <th>Име</th>
                                <th>Вноска</th>
                                <th>Платени</th>
                                <th>Остатък</th>
                            </thead>
                            <tbody>
                                {monthlySecurities.length > 0 && monthlySecurities.map((security, index) => {
                                    return (
                                        <SingleSecurity index={index} id={security.id} data={security.data} selectedMonth={selectedMonth} handleEditSecurityClick={handleEditSecurityClick} />
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr key={-1}>
                                    <td colSpan={'100%'}>
                                        * Редакцията на записите се активира при кликане върху полето предвидено за име.
                                    </td>
                                </tr>
                                <tr key={-2}>
                                    <td colSpan={'100%'}>
                                        <Button onClick={handleCreateSecurityClick}>
                                            Добави осигуряващо се лице
                                        </Button>
                                        <Button onClick={handleAccessRecurringSecuritiesClick}>
                                            Редактирай списъка с осигуровките
                                        </Button>
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                )}
            </div >
            <NewSecurityModal
                show={showNewSecurityModal}
                onHide={() => setShowNewSecurityModal(false)}
                selectedMonth={selectedMonth}
            />

            {showEditSecurityModal && (
                <EditSecurityModal
                    selectedSecurityId={selectedSecurityId}
                    selectedSecurityData={selectedSecurityData}
                    setSelectedSecurityData={setSelectedSecurityData}
                    show={showEditSecurityModal}
                    onHide={() => setShowEditSecurityModal(false)}
                />)}
        </>
    );
}

export default Securities;