
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { brokerList } from '../../resources/brokerList';

const BankInfo = ({ setSideInfo }) => {
    const [amount, setCreditAmount] = useState('')
    const [broker, setBroker] = useState('');
    const [commissionPercentage, setCommissionPercentage] = useState('');
    const [commissionManual, setCommissionManual] = useState('');
    const [commission, setCommission] = useState(0);
    const [externalRewardStatus, setExternalRewardStatus] = useState(false);
    const [externalRewardPercentage, setExternalRewardPercentage] = useState('');
    const [externalRewardManual, setExternalRewardManual] = useState('');
    const [externalReward, setExternalReward] = useState(0);
    const [brokerRewardPercentage, setBrokerRewardPercentage] = useState('');
    const [brokerRewardManual, setBrokerRewardManual] = useState('');
    const [brokerReward, setBrokerReward] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [payments, setPayments] = useState([]);


    const handleAmountChange = (e) => {
        setCreditAmount(e.target.value)
    }

    const handleBrokerSelect = (e) => {
        setBroker(e.target.value)
    }

    const changeCommissionPercentage = (e) => {
        setCommissionPercentage(e.target.value)
    }

    const handleCommissionManualChange = (e) => {
        setCommissionManual(e.target.value)
    }

    function toggleExternalRewardStatus() {
        setExternalRewardStatus(!externalRewardStatus)
    }

    const changeExternalRewardPercentage = (e) => {
        setExternalRewardPercentage(e.target.value)
    }

    const handleExternalRewardManualChange = (e) => {
        setExternalRewardManual(e.target.value)
    }

    const changeBrokerRewardPercentage = (e) => {
        setBrokerRewardPercentage(e.target.value)
    }

    const handleBrokerRewardManualChange = (e) => {
        setBrokerRewardManual(e.target.value)
    }


    // Commission
    useEffect(() => {
        calculateCommision()
    }, [amount, commissionManual, commissionPercentage])

    // Toggle External Reward
    useEffect(() => {
        if (!externalRewardStatus) {
            setExternalRewardManual('');
            setExternalRewardPercentage('');
            setBrokerReward('');
        }
    }, [externalRewardStatus])

    // External Reward
    useEffect(() => {
        calculateExternalReward()

    }, [amount, commission, externalRewardManual, externalRewardPercentage])

    // Broker Reward
    useEffect(() => {
        calculateBrokerReward()
    }, [amount, commission, externalReward, brokerRewardManual, brokerRewardPercentage])

    // Revenue
    useEffect(() => {
        calculateRevenue()
    }, [amount, commission, externalReward, brokerReward])


    // Updade Client Info in Parent Component State
    useEffect(() => {
        setSideInfo(side => ({
            ...side,
            amount: amount,
        }));

        setSideInfo(side => ({
            ...side, info: {
                broker: broker,
                commissionPercentage: commissionPercentage,
                commissionManual: commissionManual,
                commission: commission,
                externalRewardPercentage: externalRewardPercentage,
                externalRewardManual: externalRewardManual,
                externalReward: externalReward,
                brokerRewardPercentage: brokerRewardPercentage,
                brokerRewardManual: brokerRewardManual,
                brokerReward: brokerReward,
                revenue: revenue,
                payments: {}
            }
        }));
    }, [amount, broker,
        commission, commissionPercentage, commissionManual,
        externalRewardManual, externalRewardPercentage, externalReward,
        brokerRewardManual, brokerRewardPercentage, brokerReward, revenue])


    function calculateCommision() {

        if (commissionManual > 0) {
            setCommission(commissionManual)

        } else if (commissionPercentage > 0 && commissionManual == 0) {
            const currentCommission = Number((commissionPercentage / 100) * amount).toFixed(2)
            setCommission(currentCommission)

        } else {
            setCommission(0)
        }
    }


    function calculateExternalReward() {

        if (externalRewardManual > 0) {
            setExternalReward(externalRewardManual)

        } else if (externalRewardPercentage > 0 && externalRewardManual == 0) {
            const currentExternalReward = Number((externalRewardPercentage / 100) * commission).toFixed(2)
            setExternalReward(currentExternalReward)

        } else {
            setExternalReward(0)
        }
    }

    function calculateBrokerReward() {

        if (brokerRewardManual > 0) {
            setBrokerReward(brokerRewardManual)

        } else if (brokerRewardPercentage > 0 && brokerRewardManual == 0) {
            const currentBrokerReward = Number((brokerRewardPercentage / 100) * (commission - externalReward)).toFixed(2)
            setBrokerReward(currentBrokerReward)

        } else {
            setBrokerReward(0)
        }
    }

    function calculateRevenue() {
        const currentRevenue = commission - externalReward - brokerReward
        setRevenue(currentRevenue)
    }


    return (
        <div>
            <Form.Group className="mb-3" controlId="form-deal-commission-manual">
                <Form.Label><b>Банков кредит</b></Form.Label>
                <Form.Control
                    type="number" name="credit" min="0" placeholder="Въведете размера на отпуснатия кредит BGN"
                    value={amount}
                    onChange={handleAmountChange}
                />
            </Form.Group>

            {amount && (
                <div>
                    <Form.Group className="mb-3" controlId="form-deal-broker">
                        <Form.Label>Посредник</Form.Label>
                        <Form.Select name="broker" defaultValue={broker} onChange={handleBrokerSelect}>
                            <option key={-1} value={broker} disabled={true} hidden={true}>Брокер на сделката</option>
                            {brokerList.map((broker, index) =>
                                <option key={index}>{broker}</option>)
                            }
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-1" controlId="form-deal-commission-percentage">
                        <Form.Label>Комисионна</Form.Label>
                        <Form.Control
                            type="number" name="commissionPercentage" min="0" max="100" placeholder="Комисионна %"
                            value={commissionPercentage}
                            onChange={changeCommissionPercentage}
                        />
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="form-deal-commission-manual">
                        <Form.Control
                            type="number" name="commissionManual" min="0" placeholder="Въведете комисионна BGN"
                            value={commissionManual}
                            onChange={handleCommissionManualChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="form-deal-commission">
                        <Form.Control
                            type="text" name="commission"
                            value={`Комисионна: ${commission} BGN`}
                            disabled={true}
                        />
                    </Form.Group>


                    {/* {commission !== 0 && (
                        <Form.Group className="mb-1" controlId="form-deal-external">
                            <br></br>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Агентски"
                                checked={externalRewardStatus}
                                onChange={toggleExternalRewardStatus}
                            />
                        </Form.Group>
                    )
                    }

                    {commission !== 0 && externalRewardStatus && (
                        <div>
                            <Form.Group className="mb-1" controlId="form-deal-external-percentage">
                                <Form.Control type="number" name="externalRewardPercentage" min="0" max="100" placeholder="Агентски %" value={externalRewardPercentage} onChange={changeExternalRewardPercentage} />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="form-deal-external-manual">
                                <Form.Control type="number" name="externalRewardManual" min="0" placeholder="Въведете агентски BGN" value={externalRewardManual} onChange={handleExternalRewardManualChange} />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="form-deal-external">
                                <Form.Control type="text" name="externalReward" value={`Агентски: ${externalReward} BGN`} disabled={true} />
                            </Form.Group>
                        </div>
                    )
                    } */}

                    {commission !== 0 && (
                        <div>
                            <br></br>
                            <Form.Group className="mb-1" controlId="form-deal-external">
                                <Form.Label>Брокерско възнаграждение</Form.Label>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="form-deal-external">
                                <Form.Control type="number" name="brokerRewardPercentage" min="0" max="100" placeholder="Брокерско възнаграждение %" value={brokerRewardPercentage} onChange={changeBrokerRewardPercentage} />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="form-deal-external">
                                <Form.Control type="number" name="brokerRewardManual" min="0" placeholder="Въведете брокерско възнаграждение BGN" value={brokerRewardManual} onChange={handleBrokerRewardManualChange} />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="form-deal-external">
                                <Form.Control type="text" name="brokerReward" value={`Брокерско възнаграждение: ${brokerReward} BGN`} disabled={true} />
                            </Form.Group>
                        </div>
                    )
                    }
                </div>
            )}
            <hr></hr>
        </div >
    )
}

export default BankInfo;