import { Form } from "react-bootstrap";
import { useAnnum } from "../contexts/AnnualContext";
import { useEffect, useState } from "react";
import AnnumChangeConfirmationModal from "./AnnumChangeConfirmationModal";

const AnnumSelector = function () {

    const { annum, setAnnum } = useAnnum();

    const [selectedYear, setSelectedYear] = useState(annum)
    const [yearChangeConfirmation, setYearChangeConfirmation] = useState(false)
    const [showYearChangeConfirmationModal, setShowYearChangeConfirmationModal] = useState(false)



    const yearSelectionHandler = (e) => {
        setShowYearChangeConfirmationModal(true)
        setSelectedYear(e.target.value)
    }

    useEffect(() => {
        if (yearChangeConfirmation) {
            setAnnum(selectedYear)
        }
        setYearChangeConfirmation(false)
    }, [yearChangeConfirmation])

    return (
        <>
            <Form.Select aria-label="annum-selector" value={annum} onChange={yearSelectionHandler}>
                <option value={'2023'}>2023</option>
                <option value={'2024'}>2024</option>
                <option value={'2025'}>2025</option>
            </Form.Select>

            {showYearChangeConfirmationModal && (
                <AnnumChangeConfirmationModal
                    selectedYear={selectedYear}
                    setYearChangeConfirmation={setYearChangeConfirmation}
                    show={showYearChangeConfirmationModal}
                    onHide={() => setShowYearChangeConfirmationModal(false)}
                />)
            }
        </>
    );
};

export default AnnumSelector;