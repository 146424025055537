import { useState } from 'react';
import { CloseButton } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function AnnumChangeConfirmationModal({ show, onHide, setYearChangeConfirmation }) {

    const handleChangeConfirmation = () => {
        setYearChangeConfirmation(true)
        onHide()
    }

    const closeYearChangeConfirmationModal = () => {
        onHide()
    }

    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header >
                <Modal.Title>Modal title</Modal.Title>
                <CloseButton onClick={closeYearChangeConfirmationModal} />
            </Modal.Header>
            <Modal.Body>
                Сигурни ли сте, че желаете да извършите смяна на годишната референция?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeYearChangeConfirmationModal}>
                    Затвори
                </Button>
                <Button variant="warning" onClick={handleChangeConfirmation}>Потвърждавам</Button>
            </Modal.Footer>
        </Modal >
    );
}

export default AnnumChangeConfirmationModal;