import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { LoadingProvider } from "./contexts/LoadingContext";
import { RouteGuard } from './common/RouteGuard';
import Frontpage from './components/Frontpage';
import Navigation from './components/Navigation';
import Loader from './components/Loader';
import DealList from './components/accounting/DealList';
import CreateDeal from './components/accounting/CreateDeal';
import UpdateDeal from './components/accounting/update/UpdateDeal';
import Finance from './components/finance/Finance.js';
import { RecurringCosts } from './components/expenditures/recurring/RecurringCosts';
import Expenditures from './components/expenditures/Expenditures.js';
import { AnnumProvider } from './contexts/AnnualContext.js';
import Securities from './components/social-security/Securities.js';
import { RecurringSecurities } from './components/social-security/recurring/RecurringSecurities.js';




function App() {



  return (
    <LoadingProvider>
      <AuthProvider>
        <AnnumProvider>
          <div className="App">
            <Loader />
            <Navigation />
            <Routes>
              <Route element={<RouteGuard />}>
                <Route path="/financial-flow/:month" element={<Finance />} />
                <Route path="/financial-flow" element={<Finance />} />
                <Route path="/deals/create" element={<CreateDeal />} />
                <Route path="/deals/" element={<DealList />} />
                <Route path="/deals/:month" element={<DealList />} />
                <Route path="/deals/:dealId/update" element={<UpdateDeal />} />
                <Route path="/expenditures" element={<Expenditures />} />
                <Route path="/expenditures/:month" element={<Expenditures />} />
                <Route path="/expenditures-recurring" element={<RecurringCosts />} />
                <Route path="/securities/:month" element={<Securities />} />
                <Route path="/securities-recurring" element={<RecurringSecurities />} />
                <Route path="/securities" element={<Securities />} />
              </Route>
              <Route path="/" element={<Frontpage />} />
            </Routes >
          </div>
        </AnnumProvider>
      </AuthProvider>
    </LoadingProvider >
  );
}

export default App;
