import { db } from "../firebase-config";

// Deposits

export async function acquireMonthlyDepositKM(shareholderInitials, selectedMonthOrderNumber, annum, setDepositMonthlyKM) {
    await db.collection('deposits').doc(`deposit-${shareholderInitials}-${selectedMonthOrderNumber}-${annum}`).get()
        .then(deposit => { setDepositMonthlyKM(deposit.data().value) })
        .catch(error => {
            console.log(error)
        });
}


export async function acquireMonthlyDepositSK(shareholderInitials, selectedMonthOrderNumber, annum, setDepositMonthlySK) {
    await db.collection('deposits').doc(`deposit-${shareholderInitials}-${selectedMonthOrderNumber}-${annum}`).get()
        .then(deposit => { setDepositMonthlySK(deposit.data().value) })
        .catch(error => {
            console.log(error)
        });
}



export async function updateMonthlyDeposit(shareholderInitials, selectedMonthOrderNumber, annum, deposit) {
    await db.collection('deposits').doc(`deposit-${shareholderInitials}-${selectedMonthOrderNumber}-${annum}`).set(deposit);
}


export async function acquireAllDeposits(setDepositsTotal) {
    db.collection('deposits').onSnapshot(depositDatabase => {
        let depositsTotal = 0;
        depositDatabase.forEach(deposit => {
            depositsTotal += Number(deposit.data().value)
        });
        setDepositsTotal(depositsTotal)
    });
}



// Transfers



export async function acquireMonthlyTransferKM(shareholderInitials, selectedMonthOrderNumber, annum, setTransferMonthlyKM) {
    await db.collection('transfers').doc(`transfer-${shareholderInitials}-${selectedMonthOrderNumber}-${annum}`).get()
        .then(transfer => { setTransferMonthlyKM(transfer.data().value) })
        .catch(error => {
            console.log(error)
        });
}


export async function acquireMonthlyTransferSK(shareholderInitials, selectedMonthOrderNumber, annum, setTransferMonthlySK) {
    await db.collection('transfers').doc(`transfer-${shareholderInitials}-${selectedMonthOrderNumber}-${annum}`).get()
        .then(transfer => { setTransferMonthlySK(transfer.data().value) })
        .catch(error => {
            console.log(error)
        });
}

export async function updateMonthlyTransfer(shareholderInitials, selectedMonthOrderNumber, annum, transfer) {
    await db.collection('transfers').doc(`transfer-${shareholderInitials}-${selectedMonthOrderNumber}-${annum}`).set(transfer);
}


export async function acquireAllTransfers(setTransfersTotal) {
    db.collection('transfers').onSnapshot(transfersDatabase => {
        let transfersTotal = 0;
        transfersDatabase.forEach(transfer => {
            transfersTotal += Number(transfer.data().value)
        });
        setTransfersTotal(transfersTotal)
    });
}
