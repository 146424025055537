import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import { acquireAllDeals, acquireDeal, acquireDealRolesAndData, acquireDealsInMonthProAnnum, deleteDeal } from '../../services/dealService';
import { useEffect, useState } from 'react';
import { useLoading } from '../../contexts/LoadingContext';
import NestedTable from './NestedTable';
import BankTable from './BankTable';
import { useNavigate, useParams } from 'react-router';
import { Form } from 'react-bootstrap';
import NewPaymentModal from '../payments/NewPayment';
import { acquireAllPayments, createPayment } from '../../services/paymentService';
import MonthSelector from '../MonthSelector';
import { useAnnum } from '../../contexts/AnnualContext';
import { monthsBG, monthsEN } from '../../common/monthNames';


const DealList = function () {


    const params = useParams();
    const { annum } = useAnnum();
    const { setLoading } = useLoading();
    const navigate = useNavigate();

    const [selectedMonth, setSelectedMonth] = useState('')
    const [selectedMonthOrderNumber, setSelectedMonthOrderNumber] = useState('')
    const [titleMonth, setTitleMonth] = useState('');

    const [monthlyDealsCollection, setMonthlyDealsCollection] = useState('');
    const [paymentsCollection, setPaymentsCollection] = useState('');

    const [selectedDealId, setSelectedDealId] = useState('');
    const [selectedDealData, setSelectedDealData] = useState('');
    const [selectedDealRoles, setSelectedDealRoles] = useState('');

    const [showPaymentModal, setShowPaymentModal] = useState(false);



    // Set Selected Month and Acquire the Corresponding Expenditure List
    useEffect(() => {
        setSelectedMonth(params.month)
        const monthIndex = monthsEN.indexOf(params.month)
        setTitleMonth(monthsBG[monthIndex])

        setSelectedMonthOrderNumber(monthIndex + 1)

    }, [params])

    useEffect(() => {
        setMonthlyDealsCollection('')
        if (!params.month) {
            setSelectedMonth('january')
            navigate(`/deals/january`)
        }
        acquireDealsInMonthProAnnum(selectedMonthOrderNumber, annum, setMonthlyDealsCollection)
    }, [selectedMonthOrderNumber, annum])


    // Initialize Payments
    useEffect(() => {
        acquireAllPayments(setPaymentsCollection, setLoading);
    }, [])


    const handleCreateNewDealClick = (e) => {
        navigate('/deals/create')
    }

    const handleUpdateClick = (e) => {
        navigate(`/deals/${e.target.id}/update`)
    }

    const handlePaymentClick = async (e) => {
        const dealId = e.target.id;
        await acquireDealRolesAndData(dealId, setSelectedDealId, setSelectedDealData, setSelectedDealRoles, setLoading);
        setShowPaymentModal(true)
    }


    return (
        <div>
            <div className="month-selector">
                <MonthSelector />
            </div>
            {selectedMonth && (
                <div className="month-header">
                    <h5 className='header-title'>Реализирани сделки: - {annum} - {titleMonth}</h5>
                    <div className='header-button-container'>
                        <Button className='header-button' onClick={handleCreateNewDealClick}>
                            Регистирай нова сделка
                        </Button>
                    </div>
                    <hr className='header-line'></hr><hr className='header-line'></hr>
                </div>
            )}
            <div className='deal-list-container'>

                {monthlyDealsCollection.length > 0 && Object.values(monthlyDealsCollection).map((deal, index) => (

                    < div key={index} className='deal-table-container'>
                        < Table striped bordered>

                            < thead >
                                <td colSpan='2' className='deal-table-head'   >
                                    <div className='deal-title'>Сделка №</div>
                                    <div className='deal-number'>{deal.data.dealNumber}</div>
                                </td>
                            </thead >
                            < tbody >
                                < tr key={1}>
                                    <td className='view-column'>Тип :</td>
                                    {(deal.data.dealType == 'rentalDeal') ?
                                        (
                                            <td>Отдаване под наем</td>
                                        ) : (
                                            <td>Продажба на имот</td>
                                        )}
                                </tr>
                                < tr key={2}>
                                    <td className='view-column'>Дата :</td>
                                    <td>{deal.data.date}</td>

                                </tr>
                                < tr key={3}>
                                    <td className='view-column'>Цена EUR :</td>
                                    <td>{deal.data.priceEUR} €</td>
                                </tr>
                                < tr key={4}>
                                    <td>Цена BGN :</td>
                                    <td>{deal.data.priceBGN} лв.</td>
                                </tr>
                                < tr key={5}>
                                    <td colSpan='2' className='bold-title'>
                                        {deal.data.firstSide.represented}
                                    </td>
                                </tr>



                                < tr key={6}>
                                    <td >
                                        <NestedTable deal={deal.data}
                                            representedSide={"firstSide"} />
                                    </td>

                                    <td>
                                        < Table striped bordered >
                                            <thead >
                                                <td className='bold-title' colSpan={2}>Плащания</td>
                                            </thead>
                                            <tbody>
                                                {/* First Side Payments */}
                                                {paymentsCollection && paymentsCollection.map((paymentEntry, index) => {
                                                    if (paymentEntry.data.dealId === deal.id &&
                                                        paymentEntry.data.role === deal.data.firstSide.represented) {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {paymentEntry.data.date}
                                                                </td>
                                                                <td>
                                                                    {paymentEntry.data.value} лв.
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })}
                                            </tbody>

                                        </Table >
                                    </td>
                                </tr>



                                < tr key={7}>
                                    <td colSpan='2' className='bold-title'>
                                        {deal.data.secondSide.represented ? deal.data.secondSide.represented : <br></br>}
                                    </td>
                                </tr>
                                < tr key={8}>
                                    <td >
                                        <NestedTable
                                            deal={deal.data}
                                            representedSide={"secondSide"} />
                                    </td>

                                    <td>
                                        < Table striped bordered >
                                            <thead >
                                                <td className='bold-title' colSpan={2}>Плащания</td>
                                            </thead>
                                            <tbody>
                                                {/* Second Side Payments */}
                                                {paymentsCollection && paymentsCollection.map((paymentEntry, index) => {
                                                    if (paymentEntry.data.dealId === deal.id &&
                                                        paymentEntry.data.role === deal.data.secondSide.represented) {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {paymentEntry.data.date}
                                                                </td>
                                                                <td>
                                                                    {paymentEntry.data.value} лв.
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })}
                                            </tbody>
                                        </Table >
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <Form.Group className="mb-1" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Бележки:</Form.Label>
                                            <Form.Control as="textarea" rows={3} value={deal.data.notes} disabled={true} />
                                        </Form.Group>
                                    </td>
                                </tr>
                                < tr key={9}>
                                    {deal.data.bankAssistance.status && (
                                        <td colSpan={2}>

                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className='bold-title'>Банков кредит</Accordion.Header>
                                                    <Accordion.Body>
                                                        < Table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Размер на кредита:</td>
                                                                    <td>{deal.data.bankAssistance.amount} лв.</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <BankTable
                                                                            deal={deal.data}
                                                                            representedSide={"bankAssistance"}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        < Table striped bordered >
                                                                            <thead >
                                                                                <td className='bold-title' colSpan={2}>Плащания</td>
                                                                            </thead>
                                                                            <tbody>
                                                                                {/* Second Side Payments */}
                                                                                {paymentsCollection && paymentsCollection.map((paymentEntry, index) => {
                                                                                    if (paymentEntry.data.dealId === deal.id &&
                                                                                        paymentEntry.data.role === "Банка") {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>
                                                                                                    {paymentEntry.data.date}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {paymentEntry.data.value} лв.
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </tbody>
                                                                        </Table >
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table >
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>

                                        </td>
                                    )}
                                </tr>

                                < tr key={110}>
                                    <td colSpan={2}>
                                        {deal.data.additionalRevenue.value > 0 && (
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className='bold-title'>Допълнителни приходи</Accordion.Header>
                                                    <Accordion.Body>
                                                        < Table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        {deal.data.additionalRevenue.source}
                                                                    </td>
                                                                    <td>
                                                                        {`${deal.data.additionalRevenue.value} лв.`}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table >
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        )}
                                    </td>
                                </tr>
                            </tbody >
                            <tfoot>
                                <tr key={11}>
                                    <td>
                                        <Button id={deal.id} variant="primary" type="submit" onClick={handleUpdateClick}>
                                            Редакция
                                        </Button>
                                        <Button id={deal.id} variant="secondary" type="submit" onClick={handlePaymentClick}>
                                            Отбележи плащане
                                        </Button>
                                    </td>
                                </tr>
                            </tfoot >
                        </Table >
                    </div >
                ))
                }
            </div >
            {selectedDealRoles && (
                <NewPaymentModal
                    selectedDealId={selectedDealId}
                    setSelectedDealId={setSelectedDealId}
                    selectedDealData={selectedDealData}
                    selectedDealRoles={selectedDealRoles}
                    show={showPaymentModal}
                    onHide={() => setShowPaymentModal(false)}
                />
            )}
        </div>
    )
};

export default DealList;