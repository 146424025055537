import { db } from "../firebase-config"


export async function acquireAllSettlements(setSettlementsCollection) {

    const settlements = []
    db.collection('settlements').onSnapshot(settlementsCollection => {
        settlementsCollection.forEach(element => {
            const settlement = {
                id: element.id,
                data: element.data()
            }
            settlements.push(settlement)
        })
        setSettlementsCollection(settlements)

    }, error => {
        console.log(`Encountered error: ${error}`);
    });
}


export async function acquireSettlementsInMonthProAnnum(selectedMonthOrderNumber, annum, setMonthlySettlementsCollection) {

    const databaseSettlements = []
    db.collection('settlements').onSnapshot(settlementsCollection => {
        settlementsCollection.forEach(databaseSettlement => {

            // Distil deal year & month order number from html date input string in format yyyy-mm-dd
            const settlementYear = Number(databaseSettlement.data().dealDate.split('-')[0])
            const settlementMonthOrder = Number(databaseSettlement.data().dealDate.split('-')[1])

            if (settlementMonthOrder == selectedMonthOrderNumber && settlementYear == annum) {
                const settlement = {
                    id: databaseSettlement.id,
                    data: databaseSettlement.data()
                }
                databaseSettlements.push(settlement)

            }
        })
        setMonthlySettlementsCollection(databaseSettlements)

    }, error => {
        console.log(`Encountered error: ${error}`);
    });
}




export async function createNewSettlement(newDeal, deal) {
    const newSettlement = {
        dealId: newDeal.id,
        dealDate: deal.date,
        firstSide: {
            offerNo: deal.firstSide.info.offerNo,
            broker: deal.firstSide.info.broker,
            brokerReward: deal.firstSide.info.brokerReward,
            brokerRewardCovered: '',
            externalReward: deal.firstSide.info.externalReward,
            externalRewardCovered: '',
        },
        secondSide: {
            offerNo: deal.secondSide.info.offerNo ? deal.secondSide.info.offerNo : '',
            broker: deal.secondSide.info.broker ? deal.secondSide.info.broker : '',
            brokerReward: deal.secondSide.info.brokerReward ? deal.secondSide.info.brokerReward : '',
            brokerRewardCovered: '',
            externalReward: deal.secondSide.info.externalReward ? deal.secondSide.info.externalReward : '',
            externalRewardCovered: ''
        },
        bankAssistance: {
            broker: deal.bankAssistance.info.broker ? deal.bankAssistance.info.broker : '',
            brokerReward: deal.bankAssistance.info.brokerReward ? deal.bankAssistance.info.brokerReward : 0,
            brokerRewardCovered: '',
            externalReward: deal.bankAssistance.info.externalReward ? deal.bankAssistance.info.externalReward : 0,
            externalRewardCovered: '',
        },


    };
    try {
        await db.collection('settlements').add(newSettlement).then(createdSettlement => {
            console.log(`Sucessfully created settlement: ${createdSettlement.id}`)

        }).catch(error => {
            console.log(error)
        })
    } catch (error) {
        console.log(error)
    }
}



export async function updateSettlementRewards(updatedDealId, updatedDealData) {

    let selectedSettlementId = '';

    await db.collection('settlements').where('dealId', '==', updatedDealId).get()
        .then(selectedSettlement => {
            selectedSettlement.forEach(settlement => {

                selectedSettlementId = settlement.id;

            })
        })

    await db.collection('settlements').doc(selectedSettlementId).update({
        dealDate: updatedDealData.date,

        'firstSide.broker': updatedDealData.firstSide.info.broker,
        'firstSide.brokerReward': updatedDealData.firstSide.info.brokerReward,
        'firstSide.externalReward': updatedDealData.firstSide.info.externalReward,

        'secondSide.broker': updatedDealData.secondSide.info.broker ? updatedDealData.secondSide.info.broker : '',
        'secondSide.brokerReward': updatedDealData.secondSide.info.brokerReward ? updatedDealData.secondSide.info.brokerReward : '',
        'secondSide.externalReward': updatedDealData.secondSide.info.externalReward ? updatedDealData.secondSide.info.externalReward : '',

        'bankAssistance.broker': updatedDealData.bankAssistance.info.broker ? updatedDealData.bankAssistance.info.broker : '',
        'bankAssistance.brokerReward': updatedDealData.bankAssistance.info.brokerReward ? updatedDealData.bankAssistance.info.brokerReward : 0,
        'bankAssistance.externalReward': updatedDealData.bankAssistance.info.externalReward ? updatedDealData.bankAssistance.info.externalReward : 0,
    })
}


export async function updateSettlementsCovered(settlementId, dealSide, updatedKey, updatedValue) {

    if (dealSide === 'firstSide') {

        if (updatedKey === 'brokerRewardCovered') {
            await db.collection('settlements').doc(settlementId).update({
                'firstSide.brokerRewardCovered': updatedValue
            })


        } else if (updatedKey === 'externalRewardCovered') {
            await db.collection('settlements').doc(settlementId).update({
                'firstSide.externalRewardCovered': updatedValue
            })


        }
    } else if (dealSide === 'secondSide') {

        if (updatedKey === 'brokerRewardCovered') {
            await db.collection('settlements').doc(settlementId).update({
                'secondSide.brokerRewardCovered': updatedValue
            })


        } else if (updatedKey === 'externalRewardCovered') {
            await db.collection('settlements').doc(settlementId).update({
                'secondSide.externalRewardCovered': updatedValue
            })


        }
    } else if (dealSide === 'bankAssistance') {

        if (updatedKey === 'brokerRewardCovered') {
            await db.collection('settlements').doc(settlementId).update({
                'bankAssistance.brokerRewardCovered': updatedValue
            })


        } else if (updatedKey === 'externalRewardCovered') {
            await db.collection('settlements').doc(settlementId).update({
                'bankAssistance.externalRewardCovered': updatedValue
            })


        }
    }
}


export async function deleteDealSettlement(deletedDealId) {

    let selectedSettlementId = '';

    await db.collection('settlements').where('dealId', '==', deletedDealId).get()
        .then(selectedSettlement => {

            selectedSettlement.forEach(settlement => {

                selectedSettlementId = settlement.id;

            })
        })
    try {
        await db.collection('settlements').doc(selectedSettlementId).delete()
    } catch (error) {
        console.log(error)
    }
}
