import { useEffect, useState } from 'react';
import { CloseButton, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { acquireSelectedPayment, deletePayment, setPayment } from '../../services/paymentService';
import { useLoading } from '../../contexts/LoadingContext';


const UpdatePaymentModal = ({ dealId, dealRoles, dealNumber, selectedPaymentId, setSelectedPaymentId, setLoading, show, onHide }) => {

    const initialPaymentState = {
        dealId: '',
        date: '',
        role: '',
        method: '',
        value: ''
    };

    const [paymentState, setPaymentState] = useState(initialPaymentState)

    useEffect(() => {
        acquireSelectedPayment(selectedPaymentId, setPaymentState, setLoading)

    }, [])


    const handlePaymentChange = (e) => {
        setPaymentState(state => ({ ...state, [e.target.name]: e.target.value }))
    }

    const handleUpdatePaymentModalClose = () => {
        setSelectedPaymentId('')
        setPaymentState(initialPaymentState)
        onHide();
    }

    const handleUpdatePaymentSubmit = () => {
        setPayment(selectedPaymentId, paymentState, setLoading)
        onHide();
        setLoading(false)
    }


    const handleDeletePaymentSubmit = () => {
        deletePayment(selectedPaymentId, setLoading)
        // Modal ?
        onHide();
        setLoading(false)
    }

    return (
        <Modal
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редакция на плащане по сделка №: {dealNumber}
                </Modal.Title>
                <CloseButton onClick={handleUpdatePaymentModalClose} />
            </Modal.Header>
            <Modal.Body>
                <div className='payment-entries-container'>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Сума BGN:</Form.Label>
                            <Form.Control name="value" type="number" defaultValue={paymentState.value} onChange={handlePaymentChange}></Form.Control>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-date">
                            <Form.Label>Дата на събитието:</Form.Label>
                            <Form.Control
                                type="date" name="date"
                                placeholder="Дата на получаване"
                                defaultValue={paymentState.date}
                                onChange={handlePaymentChange} />
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-3" controlId="payment-role">
                            <Form.Label>Платец</Form.Label>
                            <Form.Select name="role" onChange={handlePaymentChange}>
                                <option key={-1} hidden={true} value={paymentState.role}>{paymentState.role}</option>
                                {dealRoles.map((role, index) =>
                                    <option key={index} value={role}>{role}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-3" controlId="payment-method">
                            <Form.Label>Метод</Form.Label>
                            <Form.Select name="method" onChange={handlePaymentChange}>
                                <option key={1} disabled={true} hidden={true} value={''}>Начин на плащане</option>
                                <option key={2} value={'cash'}>Брой</option>
                                <option key={3} value={'wire'}>Банков превод</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleDeletePaymentSubmit} variant="danger">Изтрии</Button>
                <Button onClick={handleUpdatePaymentSubmit} variant="primary">Запази корекциите</Button>
                <Button onClick={handleUpdatePaymentModalClose} variant="secondary">Затвори</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UpdatePaymentModal;