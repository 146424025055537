import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import NewRecurringExpenditureModal from "./NewRecurring";
import { acquireAllRecurringExpenditures } from "../../../services/expenditureService";
import EditRecurringExpenditureModal from "./EditRecurring";

export function RecurringCosts() {


    const [recurringExpendituresCollection, setRecurringExpendituresCollection] = useState('')
    const [selectedRecurringExpenditureId, setSelectedRecurringExpenditureId] = useState('')
    const [selectedRecurringExpenditureData, setSelectedRecurringExpenditureData] = useState('')
    const [showNewRecurringExpenditureModal, setShowNewRecurringExpenditureModal] = useState(false);
    const [showEditRecurringExpenditureModal, setShowEditRecurringExpenditureModal] = useState(false);

    useEffect(() => {
        acquireAllRecurringExpenditures(setRecurringExpendituresCollection)
    }, [])


    const handleCreateRecurringExpenditureClick = (e) => {
        e.preventDefault();
        setShowNewRecurringExpenditureModal(true)

    }

    const handleEditRecurringExpenditureClick = (e) => {

        setSelectedRecurringExpenditureId(e.currentTarget.id)
        setShowEditRecurringExpenditureModal(true)
    }

    return (
        <div>
            <div className="recurring-costs-container">
                <Table striped bordered hover variant="light" >
                    <thead>
                        <tr key={0}>
                            <th>
                                Разход
                            </th>
                            <th>
                                Фактуриране
                            </th>
                            <th>
                                Начин на плащане
                            </th>
                            <th>
                                Сума без ДДС
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {recurringExpendituresCollection && recurringExpendituresCollection.map((recurringExpenditure, index) => {
                            return (<tr id={recurringExpenditure.id} key={index} onClick={handleEditRecurringExpenditureClick}>
                                <td>{recurringExpenditure.data.name}</td>
                                <td>{(recurringExpenditure.data.invoice === 'true') ? "Фактура" : "Нефактуриран"}</td>
                                <td>{(recurringExpenditure.data.method === 'wire') ? "Банков превод" : "Брой"}</td>
                                <td>{recurringExpenditure.data.value}</td>
                            </tr>)
                        })}
                    </tbody>
                </Table>
                <div>

                    <Button variant="primary" type="submit" onClick={handleCreateRecurringExpenditureClick}>
                        Създай нов периодичен разход
                    </Button>
                </div>
                <label>
                    * За редакция на периодичен разход, кликнете върху съответната графа
                </label>
            </div>

            <NewRecurringExpenditureModal
                show={showNewRecurringExpenditureModal}
                onHide={() => setShowNewRecurringExpenditureModal(false)}
            />

            {showEditRecurringExpenditureModal && selectedRecurringExpenditureId && (<EditRecurringExpenditureModal
                selectedRecurringExpenditureId={selectedRecurringExpenditureId}
                selectedRecurringExpenditureData={selectedRecurringExpenditureData}
                setSelectedRecurringExpenditureData={setSelectedRecurringExpenditureData}
                show={showEditRecurringExpenditureModal}
                onHide={() => setShowEditRecurringExpenditureModal(false)}
            />)
            }

        </div>
    )
}