
import { useState } from 'react';
import { CloseButton, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { createNewRecurringExpenditure } from '../../../services/expenditureService';
import { createRecurringSocialSecurity } from '../../../services/securitiesService';


const NewRecurringSecuritiesModal = ({ show, onHide }) => {

    const initialRecurringSocialSecurityState = {
        name: '',
        value: '',
        covered: '',
    };

    const [recurringSocialSecurity, setRecurringSocialSecurityState] = useState(initialRecurringSocialSecurityState)

    const handleSocialSecurityChange = (e) => {
        setRecurringSocialSecurityState(state => ({ ...state, [e.target.name]: e.target.value }))
    }


    const handleCreateRecurringSecuritySubmit = (e) => {
        e.preventDefault();

        createRecurringSocialSecurity(recurringSocialSecurity)

        setRecurringSocialSecurityState(initialRecurringSocialSecurityState)

        onHide();
    }


    const recurringSocialSecurityModalClose = () => {

        setRecurringSocialSecurityState(initialRecurringSocialSecurityState)

        onHide();
    }

    return (
        <Modal
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Ново осигуряващо се лице:
                </Modal.Title>
                <CloseButton onClick={recurringSocialSecurityModalClose} />
            </Modal.Header>
            <Modal.Body>
                <div className='security-entries-container'>
                    <div className='security-entry-item'>
                        <Form.Group className="mb-1" controlId="security-name" >
                            <Form.Label>Име:</Form.Label>
                            <Form.Control name="name" type="text" onChange={handleSocialSecurityChange}></Form.Control>
                        </Form.Group>
                    </div>
                    <div className='security-entry-item'>
                        <Form.Group className="mb-1" controlId="security-value" >
                            <Form.Label>Осигурителна вноска BGN:</Form.Label>
                            <Form.Control name="value" type="number" onChange={handleSocialSecurityChange}></Form.Control>
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCreateRecurringSecuritySubmit} variant="primary">Запиши данните</Button>
                <Button onClick={recurringSocialSecurityModalClose} variant="secondary">Затвори</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NewRecurringSecuritiesModal;