import { useEffect, useState } from "react";
import { acquireAdministrationRewardCovered, createAdministrationExpenditure, updateAdministrationExpenditure, updateAdministrationExpenditureCovered } from "../../../services/expenditureService";
import { Form } from "react-bootstrap";


function AdministrationExpenditure({ monthlyPaymentsCollection, annum, selectedMonth, selectedMonthOrderNumber }) {

    const [administrationReward, setAdministrationReward] = useState(0)
    const [administrationRewardCovered, setAdministrationRewardCovered] = useState(0)

    const initialExpenditure = {
        name: 'Администрация',
        month: selectedMonth,
        year: annum,
        invoice: 'false',
        invoiceNo: '',
        method: 'cash',
        value: administrationReward,
        covered: 0,
    };

    useEffect(() => {
        createAdministrationExpenditure(initialExpenditure, selectedMonthOrderNumber)
        updateAdministrationExpenditure(administrationReward, selectedMonthOrderNumber)
    }, [administrationReward])




    // Initialize Administration Reward
    useEffect(() => {
        let receivedPayments = 0;
        if (monthlyPaymentsCollection) {
            monthlyPaymentsCollection.forEach(payment => {
                receivedPayments += Number(payment.data.value)
            });
        }
        setAdministrationReward((receivedPayments * 0.01).toFixed(2))
    }, [monthlyPaymentsCollection])

    // Set Initial Reward Covered
    useEffect(() => {
        acquireAdministrationRewardCovered(selectedMonthOrderNumber, setAdministrationRewardCovered)
    }, [annum, selectedMonthOrderNumber])



    const handleAdministrationChange = (e) => {
        setAdministrationRewardCovered(e.target.value)
        updateAdministrationExpenditureCovered(e.target.value, selectedMonthOrderNumber)
    }


    return (
        <tr key={-1}>
            <td colSpan={4}>Администрация: </td>
            <td colSpan={2}>{administrationReward}</td>
            <td>
                <Form.Control name="covered" type="text" value={administrationRewardCovered} onChange={handleAdministrationChange}></Form.Control>
            </td>
            <td>{(administrationReward - administrationRewardCovered).toFixed(2)}</td>
        </tr>
    )
}

export default AdministrationExpenditure;