import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Firebase configuration
const config = {
    apiKey: "AIzaSyBrtouxjCdWmvffwHC0PHgIYWlfdy9mVjA",
    authDomain: "jivena-accounting-and-finance.firebaseapp.com",
    projectId: "jivena-accounting-and-finance",
    storageBucket: "jivena-accounting-and-finance.appspot.com",
    messagingSenderId: "980070068700",
    appId: "1:980070068700:web:e31451299e2188a423df19"
};

// Initialize Firebase
firebase.initializeApp(config);
export const auth = firebase.auth();
export const db = firebase.firestore();

// Update Firestore Settings
db.settings({ timestampsInSnapshots: true })

