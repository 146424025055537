import { createContext, useContext, useState } from "react";

const AnnualContext = createContext({
    annum: false,
    setAnnum: null,
});

export function AnnumProvider({ children }) {
    const [annum, setAnnum] = useState(2024);
    const value = { annum, setAnnum };
    return (
        <AnnualContext.Provider value={value}>{children}</AnnualContext.Provider>
    );
}

export function useAnnum() {
    const context = useContext(AnnualContext);
    if (!context) {
        throw new Error("useAnnum must be used within LoadingProvider");
    }
    return context;
}