import Spinner from 'react-bootstrap/Spinner';
import { useLoading } from '../contexts/LoadingContext';


function Loader() {

    const { loading } = useLoading();

    if (loading) {

        return (
            <div className='loader-overlay'>
                <Spinner animation="border" role="status" style={{ width: "8rem", height: "8rem" }}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        )
    }


}

export default Loader;