import { db } from "../firebase-config"
import { deleteDealPayments } from "./paymentService";
import { createNewSettlement, deleteDealSettlement, updateSettlementRewards } from "./settlementService";


export async function createDeal(deal, setLoading) {
    setLoading(true);
    try {
        await db.collection('deals').add(deal)
            .then(newDeal => {


                console.log(`Deal ${newDeal.id} Created Successfully!`);

                //    db.collection('offers').doc(newOffer.id).update({ ...values, ['uid']: newOffer.id })

                // Create Deal Settlement in Settlements Collection
                createNewSettlement(newDeal, deal)
                setLoading(false);

            })
        // .catch(error => {
        //     console.log(error)
        //     setLoading(false);
        // });
    } catch (error) {
        console.log(error)
        setLoading(false);
    }
}


export async function updateDeal(updatedDeal) {
    await db.collection('deals').doc(updatedDeal.id).set(updatedDeal.data);

    // Update Deal Settlement in Settlements Collection
    updateSettlementRewards(updatedDeal.id, updatedDeal.data)
}



export async function acquireDeal(dealId, setSelectedDealId, setSelectedDealData, setLoading) {
    setLoading(true);

    db.collection('deals').doc(dealId).onSnapshot(async firestoreDeal => {

        setSelectedDealId(firestoreDeal.id)
        setSelectedDealData(firestoreDeal.data())
    })
    setLoading(false);
}

export async function acquireDealRolesAndData(dealId, setSelectedDealId, setSelectedDealData, setSelectedDealRoles, setLoading) {
    setLoading(true);

    db.collection('deals').doc(dealId).onSnapshot(async firestoreDeal => {

        const selectedDeal = firestoreDeal.data()
        const dealRoles = [];

        if (selectedDeal.firstSide.represented) {
            dealRoles.push(firestoreDeal.data().firstSide.represented)
        }
        if (selectedDeal.secondSide.represented) {
            dealRoles.push(selectedDeal.secondSide.represented)
        }
        if (selectedDeal.bankAssistance.status) {
            dealRoles.push("Банка")
        }

        setSelectedDealId(firestoreDeal.id)
        setSelectedDealData(firestoreDeal.data())
        setSelectedDealRoles(dealRoles)

    })
    setLoading(false);
}


export async function acquireDealsInMonthProAnnum(selectedMonthOrderNumber, annum, setMonthlyDealsCollection) {

    const firestoreDeals = []
    db.collection('deals').onSnapshot(dealsCollection => {
        dealsCollection.forEach(firestoreDeal => {

            // Distil deal year & month order number from html date input string in format yyyy-mm-dd
            const dealYear = Number(firestoreDeal.data().date.split('-')[0])
            const dealMonthOrder = Number(firestoreDeal.data().date.split('-')[1])

            if (dealMonthOrder == selectedMonthOrderNumber && dealYear == annum) {
                const deal = {
                    id: firestoreDeal.id,
                    data: firestoreDeal.data()
                }
                firestoreDeals.push(deal)

            }
        })
        setMonthlyDealsCollection(firestoreDeals)

    }, error => {
        console.log(`Encountered error: ${error}`);
    });
}



export async function acquireAllDeals(setDealsCollection, setLoading) {
    setLoading(true);
    const firestoreDeals = []
    db.collection('deals').onSnapshot(dealsCollection => {
        dealsCollection.forEach(firestoreDeal => {
            const deal = {
                id: firestoreDeal.id,
                data: firestoreDeal.data()
            }
            firestoreDeals.push(deal)
        })
        setDealsCollection(firestoreDeals)
        setLoading(false);
    }, error => {
        console.log(`Encountered error: ${error}`);
    });
}



export async function deleteDeal(dealId) {

    deleteDealSettlement(dealId)

    deleteDealPayments(dealId)

    await db.collection('deals').doc(dealId).delete();

}





