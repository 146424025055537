import { db } from "../firebase-config";


// Acquire Montly Securities for a given year
export async function acquireMonthlySecuritiesProAnnum(annum, selectedMonth, setMonthlySecurities) {
    if (annum && selectedMonth && setMonthlySecurities) {

        db.collection('securities').where('month', '==', selectedMonth).onSnapshot(snapshot => {

            const currentMonthlySecurities = []
            snapshot.forEach(security => {

                if (security.data().year == annum) {
                    const currentSecurity = {
                        id: security.id,
                        data: security.data()
                    }
                    currentMonthlySecurities.push(currentSecurity)
                }
            })
            setMonthlySecurities(currentMonthlySecurities)
        })
    }
}




// Create Monthly Securities
export function createMonthlySecurities(selectedMonth, annum, recurringSecurities) {

    recurringSecurities.forEach(security => {

        db.collection('securities').add(security.data)
            .then(security => {
                db.collection('securities').doc(security.id).update({
                    'year': annum,
                    'month': selectedMonth
                })
            })
            .catch(error => {
                console.log(error)
            });
    })
}

export async function createNewSecurity(newSecurity, annum, selectedMonth, setLoading) {
    setLoading(true)

    db.collection('securities').add(newSecurity).then(security => {
        db.collection('securities').doc(security.id).update({
            'year': annum,
            'month': selectedMonth
        })
        setLoading(false)
    }).catch(error => {
        console.log(error)
        setLoading(false)
    });
}


export async function updateSingleSecurityValue(selectedSecurityId, updatedKey, updatedValue) {

    if (updatedKey === 'name') {
        await db.collection('securities').doc(selectedSecurityId).update({
            'name': updatedValue
        })
    } else if (updatedKey === 'covered') {
        await db.collection('securities').doc(selectedSecurityId).update({
            'covered': updatedValue
        })
    }

}


export async function acquireSelectedSecurity(selectedSecurityId, selectedSecurityData, setSelectedSecurityData) {
    db.collection('securities').doc(selectedSecurityId).onSnapshot(security => {
        setSelectedSecurityData(security.data())
    })
}


export async function updateSelectedSecurity(selectedSecurityId, selectedSecurityData) {

    await db.collection('securities').doc(selectedSecurityId).set(selectedSecurityData)
}


export async function deleteSelectedSecurity(selectedSecurityId) {

    await db.collection('securities').doc(selectedSecurityId).delete()
}




/////////////////////////////////////////////////////////////////////////////////
//                                                                             //
//                            RECURRING SECURITIES                             //
//                                                                             //
/////////////////////////////////////////////////////////////////////////////////





export function createRecurringSocialSecurity(recurringSocialSecurity) {
    db.collection('insured').add(recurringSocialSecurity)
}


export async function acquireRecurringSecurity(selectedRecurringSecurityId, setSelectedRecurringSecurityData) {
    const currentSelectedSecurity = await db.collection('insured').doc(selectedRecurringSecurityId).get();

    setSelectedRecurringSecurityData(currentSelectedSecurity.data())
}

export async function acquireAllRecurringSecurities(setRecurringSecuritiesCollection) {
    db.collection('insured').onSnapshot(recurringSecurities => {
        const currentRecurringSecurities = []
        recurringSecurities.forEach(recurringSecurity => {
            const security = {
                id: recurringSecurity.id,
                data: recurringSecurity.data()
            }
            currentRecurringSecurities.push(security)
        })
        setRecurringSecuritiesCollection(currentRecurringSecurities)
    })
}


export async function updateRecurringSecurity(selectedRecurringSecurityId, selectedRecurringSecurityData) {
    await db.collection('insured').doc(selectedRecurringSecurityId).set(selectedRecurringSecurityData)
}


export async function deleteRecurringSecurity(selectedRecurringSecurityId) {
    await db.collection('insured').doc(selectedRecurringSecurityId).delete()
}
