import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
// import NewRecurringSecuritiesModal from "./NewRecurringSecurity";

import NewRecurringSecuritiesModal from "./NewRecurringSecurity";
import { acquireAllRecurringSecurities } from "../../../services/securitiesService";
import EditRecurringSecurityModal from "./EditRecurringSecurity";
// import EditRecurringSecuritiesModal from "./EditRecurringSecurity";

export function RecurringSecurities() {


    const [recurringSecuritiesCollection, setRecurringSecuritiesCollection] = useState('')
    const [selectedRecurringSecurityId, setSelectedRecurringSecurityId] = useState('')
    const [selectedRecurringSecurityData, setSelectedRecurringSecurityData] = useState('')
    const [showNewRecurringSecuritiesModal, setShowNewRecurringSecuritiesModal] = useState(false);
    const [showEditRecurringSecuritiesModal, setShowEditRecurringSecuritiesModal] = useState(false);

    useEffect(() => {
        acquireAllRecurringSecurities(setRecurringSecuritiesCollection)
    }, [])


    const handleCreateRecurringSecurityClick = (e) => {
        e.preventDefault();
        setShowNewRecurringSecuritiesModal(true)

    }

    const handleEditRecurringSecurityClick = (e) => {

        setSelectedRecurringSecurityId(e.currentTarget.id)
        setShowEditRecurringSecuritiesModal(true)
    }

    return (
        <div>
            <div className="recurring-costs-container">
                <Table striped bordered hover variant="light" >
                    <thead>
                        <tr key={0}>
                            <th цол>
                                Име
                            </th>
                            <th>
                                Осигурителна вноска
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {recurringSecuritiesCollection && recurringSecuritiesCollection.map((recurringSecurity, index) => {
                            return (<tr id={recurringSecurity.id} key={index} onClick={handleEditRecurringSecurityClick}>
                                <td>{recurringSecurity.data.name}</td>
                                <td>{recurringSecurity.data.value} лв.</td>
                            </tr>)
                        })}
                    </tbody>
                </Table>
                <div>

                    <Button variant="primary" type="submit" onClick={handleCreateRecurringSecurityClick}>
                        Добави осигуряващо се лице
                    </Button>
                </div>
                <label>
                    * За редакция на данните на осигуряващо се лице, кликнете върху съответната графа
                </label>
            </div>

            <NewRecurringSecuritiesModal
                show={showNewRecurringSecuritiesModal}
                onHide={() => setShowNewRecurringSecuritiesModal(false)}
            />

            {showEditRecurringSecuritiesModal && (<EditRecurringSecurityModal
                selectedRecurringSecurityId={selectedRecurringSecurityId}
                selectedRecurringSecurityData={selectedRecurringSecurityData}
                setSelectedRecurringSecurityData={setSelectedRecurringSecurityData}
                show={showEditRecurringSecuritiesModal}
                onHide={() => setShowEditRecurringSecuritiesModal(false)}
            />)
            }

        </div>
    )
}