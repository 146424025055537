import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { updateSingleSecurityValue } from "../../services/securitiesService";


export function SingleSecurity({ index, id, data, selectedMonth, handleEditSecurityClick }) {

    const [value, setValue] = useState('')
    const [covered, setCovered] = useState(0)
    const [outstanding, setOutstanding] = useState(0)


    useEffect(() => {
        setValue(data.value)
    }, [])


    useEffect(() => {
        setCovered(data.covered)
    }, [])


    useEffect(() => {
        setOutstanding((data.value - data.covered).toFixed(2))
    }, [])

    useEffect(() => {
        setOutstanding((value - covered).toFixed(2))
    }, [id, value, covered])



    const handleSecurityCoveredChange = (e) => {

        const updatedKey = e.target.name;
        const updatedValue = e.target.value;

        if (e.target.name == 'covered') {
            setCovered(updatedValue)
        }

        const selectedSecurityId = e.currentTarget.parentNode.parentNode.id;
        updateSingleSecurityValue(selectedSecurityId, updatedKey, updatedValue, selectedMonth)
    }



    return (
        < tr key={index} id={id} onClick={handleEditSecurityClick}>
            <td id={"name"}>
                {data.name}
            </td>
            <td id={"value"}>
                {data.value}
            </td>
            <td id="covered">
                <Form.Control name="covered" type="number" value={covered} onChange={handleSecurityCoveredChange}></Form.Control>
            </td>
            <td id={"outstanding"}>
                {outstanding}
            </td>
        </tr >
    )
}