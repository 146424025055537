import { createContext, useState } from 'react';
import { auth, db } from '../firebase-config';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({
    children,
}) => {

    const [userToken, setCurrentUser] = useState('');
    const [fireAuthCheck, setFireAuthCheck] = useState(true);
    const navigate = useNavigate();


    // Monitor Auth State
    useEffect(() => {
        if (fireAuthCheck) {

            auth.onAuthStateChanged(userToken => {

                if (userToken) {
                    console.log(`User with email ${userToken.email} logged in successfully!`)
                    setCurrentUser(userToken)
                } else {
                    setCurrentUser(userToken)
                    console.log("No user signed in!")
                }
            })
            setFireAuthCheck(false)
        }
    }, []);


    const contextValues = {
        navigate,
        userToken
    }

    return (
        <>
            <AuthContext.Provider value={contextValues}>
                {children}
            </AuthContext.Provider>
        </>
    )

}
