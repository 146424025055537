import Table from "react-bootstrap/esm/Table";


function NestedTable({ deal, representedSide }) {

    return (
        < Table striped bordered >
            <thead >
                <td className='bold-title' colSpan={2}>Възнаграждения</td>
            </thead>

            <tr key={1}>
                <td>Комисионна:</td>
                <td>{deal[representedSide].info.commission} лв.</td>
            </tr>
            <tr key={2}>
                <td>Отговорен: <br></br>
                    {deal[representedSide].info.broker}
                </td>
            </tr>
            <tr key={3}>
                <td>Брокер:</td>
                <td>{deal[representedSide].info.brokerReward} лв.</td>
            </tr>
            <tr key={4}>
                <td>Външен агент:</td>
                <td>{deal[representedSide].info.externalReward} лв.</td>
            </tr>

        </Table >
    )
}

export default NestedTable;