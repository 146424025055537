import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import ClientInfo from './ClientInfo';
import BankInfo from './BankInfo';
import { createDeal } from '../../services/dealService';
import { useLoading } from '../../contexts/LoadingContext';
import { useNavigate } from 'react-router';


const CreateDeal = function () {


    const euroBGN = 1.95583;
    const initialSideState = {
        represented: '',
        info: {},
    };
    const initialBankState = {
        status: '',
        amount: 0,
        info: {},
    };

    const initialRevenueState = {
        source: '',
        value: 0,
    };

    const initialDealDataStructure = {
        dealType: '',
        date: '',
        dealNumber: '',
        priceEUR: '',
        priceBGN: '',
        clients: '',
        firstSide: initialSideState,
        secondSide: initialSideState,
        bankAssistance: initialBankState,
        additionalRevenue: initialRevenueState,
        totalRevenue: '',
        notes: '',
    };

    const { setLoading } = useLoading();
    const navigate = useNavigate()

    const [dealType, setDealType] = useState('');
    const [date, setDate] = useState('');
    const [dealNumber, setNumber] = useState('');
    const [priceEUR, setPriceEUR] = useState('');
    const [priceBGN, setPriceBGN] = useState('');
    const [clients, setClients] = useState('');
    const [firstSide, setFirstSide] = useState(initialSideState);
    const [secondSide, setSecondSide] = useState(initialSideState);
    const [bankAssistance, setBankAssistance] = useState(initialBankState);
    const [additionalRevenue, setAdditionalRevenue] = useState(initialRevenueState);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [notes, setNote] = useState('');
    const [newDealInfo, setNewDealInfo] = useState('');

    const handleDealTypeChange = (e) => {
        setDealType(e.target.value);
    };

    const handleDateSelect = (e) => {
        setDate(e.target.value);
    };

    const handleClientsChange = (e) => {
        setClients(e.target.value);
    };

    const handleNoteEntry = (e) => {
        setNote(e.target.value);
    };

    const changePriceEUR = (e) => {
        const priceEUR = e.target.value
        setPriceEUR(priceEUR);
        calculatePriceBGN(priceEUR);

    };

    const calculatePriceBGN = (priceEUR) => {
        const priceBGN = (priceEUR * euroBGN).toFixed(2);
        setPriceBGN(priceBGN);
    };

    const handleRevenueEntry = (e) => {
        setAdditionalRevenue(revenue => ({ ...revenue, [e.target.name]: e.target.value }))

    };


    // Submit
    const handleCreateDealSubmit = (e) => {
        e.preventDefault()
        createDeal(newDealInfo, setLoading)
        setNewDealInfo(initialDealDataStructure)
        navigate('/deals')
    }

    // Initialize New Deal
    useEffect(() => {
        const dealDataStructure = {
            dealType: dealType,
            date: date,
            dealNumber: dealNumber,
            priceEUR: priceEUR,
            priceBGN: priceBGN,
            clients: clients,
            firstSide: firstSide,
            secondSide: secondSide,
            bankAssistance: bankAssistance,
            additionalRevenue: additionalRevenue,
            totalRevenue: totalRevenue,
            notes: notes,
        };
        setNewDealInfo(dealDataStructure)

    }, [dealType, date, dealNumber, priceEUR, clients,
        firstSide, secondSide, bankAssistance,
        additionalRevenue, totalRevenue, notes,]);

    // Type
    useEffect(() => {
        setClients('')
        setFirstSide(initialSideState);
        setSecondSide(initialSideState);
        setBankAssistance(initialBankState);
    }, [dealType]);

    // Clients
    useEffect(() => {
        if (clients === 'tenant') {
            setFirstSide(firstSide => ({ ...firstSide, represented: 'Наемател' }));
            setSecondSide(initialSideState);
            setBankAssistance(initialBankState);

        } else if (clients === 'landlord') {
            setFirstSide(firstSide => ({ ...firstSide, represented: 'Наемодател' }));
            setSecondSide(initialSideState);
            setBankAssistance(initialBankState);

        } else if (clients === 'doubleRentalDeal') {
            setFirstSide(firstSide => ({ ...firstSide, represented: 'Наемател' }));
            setSecondSide(secondSide => ({ ...secondSide, represented: 'Наемодател' }));
            setBankAssistance(initialBankState);

        } else if (clients === 'buyer') {
            setFirstSide(firstSide => ({ ...firstSide, represented: 'Купувач' }));
            setSecondSide(initialSideState);

        } else if (clients === 'seller') {
            setFirstSide(firstSide => ({ ...firstSide, represented: 'Продавач' }));
            setSecondSide(initialSideState);
        } else if (clients === 'doublePurchaseDeal') {
            setFirstSide(firstSide => ({ ...firstSide, represented: 'Купувач' }));
            setSecondSide(secondSide => ({ ...secondSide, represented: 'Продавач' }));
        }
    }, [clients]);


    // Number
    useEffect(() => {
        const firstSideNumber = firstSide.info.offerNo;
        const secondSideNumber = secondSide.info.offerNo;
        if (firstSide.info.offerNo && secondSide.info.offerNo) {
            setNumber(`${firstSideNumber}.${secondSideNumber}`);
        } else {
            setNumber(firstSideNumber);
        }
    }, [firstSide.info.offerNo, secondSide.info.offerNo])

    // Total Revenue (NB: To include total additional revenue in future updates)
    useEffect(() => {

        let firstSideRevenue = Number(firstSide.info.revenue);
        let secondSideRevenue = Number(secondSide.info.revenue);
        let bankSideRevenue = Number(bankAssistance.info.revenue);
        let additional = Number(additionalRevenue.value);
        let addends = [firstSideRevenue, secondSideRevenue, bankSideRevenue, additional]
        let totalRevenue = 0
        addends.forEach(addend => {
            if (addend) {
                totalRevenue += addend
            }
        })

        if (totalRevenue > 0) {
            setTotalRevenue(totalRevenue)
        } else {
            setTotalRevenue('')
        }
    }, [priceEUR, firstSide.info.revenue, secondSide.info.revenue, bankAssistance.info.revenue, additionalRevenue.value])


    return (
        <Form>

            <div className="edit-deal-container" >
                <div className='edit-fullspan-container'>
                    <Form.Group className="mb-3" controlId="notes">
                        <Form.Label><b>Нова сделка № : {dealNumber ? dealNumber : 'Автоматичен'}</b></Form.Label>
                    </Form.Group>
                </div>

                <div className='grid-container'>
                    <div className='grid-item'>
                        {/* <div className='edit-mode-column'> */}

                        <Form.Group className="mb-3" controlId="form-deal-type">
                            <Form.Label>Основна информация</Form.Label>
                        </Form.Group>
                        <hr></hr>

                        <Form.Group className="mb-3" controlId="form-deal-type">
                            <Form.Label>Вид сделка</Form.Label>
                            <Form.Select
                                aria-label="Select deal type"
                                name="type"
                                value={dealType}
                                onChange={handleDealTypeChange}>
                                <option value="" disabled={true} hidden={true}>Посочете вида на сделката</option>
                                <option value="purchaseDeal">Продажба</option>
                                <option value="rentalDeal">Отдаване под наем</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="form-deal-value">
                            <Form.Label>Дата на сключване</Form.Label>
                            <Form.Control
                                type="date" name="date"
                                placeholder="Псочете дата на сключване"
                                value={date}
                                onChange={handleDateSelect} />
                        </Form.Group>

                        {dealType === "rentalDeal" && (
                            <Form.Group className="mb-3" controlId="form-side" >
                                <Form.Label>Представителство</Form.Label>
                                <Form.Select
                                    aria-label="Select deal side"
                                    name="clients"
                                    value={clients}
                                    onChange={handleClientsChange}>
                                    <option value="" disabled={true} hidden={true}>Посочете представляваната страна</option>
                                    <option value="landlord">Наемодател</option>
                                    <option value="tenant">Наемател</option>
                                    <option value="doubleRentalDeal">Двете страни</option>
                                </Form.Select>
                            </Form.Group>
                        )}

                        {dealType === "purchaseDeal" && (
                            <Form.Group className="mb-3" controlId="form-side">
                                <Form.Label>Представителство</Form.Label>
                                <Form.Select
                                    aria-label="Select deal side"
                                    name="clients"
                                    value={clients}
                                    onChange={handleClientsChange}>
                                    <option value="" disabled={true} hidden={true}>Посочете представляваната страна</option>
                                    <option value="seller">Продавач</option>
                                    <option value="buyer">Купувач</option>
                                    <option value="doublePurchaseDeal">Двете страни</option>
                                </Form.Select>
                            </Form.Group>
                        )}

                        {dealType && clients && (
                            <div><span></span>
                                <table className='price-table'>
                                    <thead></thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Form.Group className="mb-3" controlId="form-deal-value-EUR">
                                                    <Form.Label>Стойност EUR</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="priceEUR"
                                                        placeholder="Въведете стойност (EUR)"
                                                        value={priceEUR}
                                                        onChange={changePriceEUR} />
                                                </Form.Group>

                                            </td>
                                            <td>
                                                <Form.Group className="mb-3" controlId="form-deal-value-BGN">
                                                    <Form.Label>Стойност BGN</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="priceBGN"
                                                        value={priceBGN}
                                                        disabled={true} />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}

                        <Form.Group className="mb-3" controlId="notes">
                            <Form.Label>Бележки</Form.Label>
                            <Form.Control as="textarea" rows={4} value={notes} onChange={handleNoteEntry} />
                        </Form.Group>
                        <hr></hr>



                    </div>

                    <div className='grid-item'>
                        {/* <div className='edit-mode-column'> */}
                        <Form.Group className="mb-3" controlId="form-deal-type">
                            <Form.Label>Първа страна</Form.Label>
                        </Form.Group>

                        <hr></hr>

                        {dealType && clients && priceEUR && (
                            <ClientInfo
                                dealValueBGN={priceBGN}
                                representedSide={firstSide.represented}
                                setSideInfo={setFirstSide}
                            />
                        )}
                    </div>

                    <div className='grid-item'>
                        {/* <div className='edit-mode-column'> */}
                        <Form.Group className="mb-3" controlId="form-deal-type">
                            <Form.Label>Втора страна</Form.Label>
                        </Form.Group>
                        <hr></hr>

                        {['doubleRentalDeal', 'doublePurchaseDeal'].includes(clients) && dealType && priceEUR && (
                            <ClientInfo
                                dealValueBGN={priceBGN}
                                representedSide={secondSide.represented}
                                setSideInfo={setSecondSide}
                            />

                        )}
                    </div>
                </div>
                <div className='edit-fullspan-container-left'>

                    {['doublePurchaseDeal', 'buyer', 'seller'].includes(clients) && dealType && priceEUR && (
                        <div>
                            <hr></hr>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Банков кредит"
                                checked={bankAssistance.status}
                                onChange={() => {
                                    setBankAssistance(bankAssistance => ({ ...bankAssistance, status: !bankAssistance.status }))
                                }}
                            />
                            <hr></hr>
                        </div>
                    )}

                    <div >
                        {bankAssistance.status && (
                            <BankInfo
                                setSideInfo={setBankAssistance}
                            />
                        )}
                    </div >

                    {/* <div >
                        {priceBGN && dealType && clients && (
                            <Form.Group className="mb-3" controlId="additional-revenue">
                                <table className='additional-revenue-table'>
                                    <thead>
                                        <tr>
                                            <td colSpan={2}><Form.Label>Допълнителни приходи</Form.Label></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Form.Group className="mb-1" controlId="notes-title">
                                                    <Form.Control name="source" type="text" placeholder='Тип приход' value={additionalRevenue.source} onChange={handleRevenueEntry} />
                                                </Form.Group>
                                            </td>
                                            <td>
                                                <Form.Group className="mb-1" controlId="notes-title">
                                                    <Form.Control name="value" type="number" placeholder='Размер BGN' value={additionalRevenue.value} onChange={handleRevenueEntry} />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Form.Group>
                        )}
                    </div > */}

                    <div>
                        <Form.Group className="mb-1" controlId="notes-title">
                            <Form.Control name="totalRevenue" type="text" value={`Фирмени: ${totalRevenue} BGN`} disabled={true} />
                        </Form.Group>
                    </div>

                    <div>
                        <Button variant="primary" type="submit" onClick={handleCreateDealSubmit}>
                            Запис
                        </Button>
                    </div >
                </div>
            </div >
        </Form >

    );
};

export default CreateDeal;