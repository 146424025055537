import { useEffect, useState } from 'react';
import { CloseButton, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { acquireRecurringExpenditure, deleteRecurringExpenditure, updateRecurringExpenditure } from '../../../services/expenditureService';


const EditRecurringExpenditureModal = ({ selectedRecurringExpenditureId, selectedRecurringExpenditureData, setSelectedRecurringExpenditureData, show, onHide }) => {

    useEffect(() => {

        acquireRecurringExpenditure(selectedRecurringExpenditureId, setSelectedRecurringExpenditureData)

    }, [selectedRecurringExpenditureId])



    const handleExpenditureChange = (e) => {

        setSelectedRecurringExpenditureData(state => ({ ...state, [e.target.name]: e.target.value }))
    }




    const handleUpdateRecurringSubmit = (e) => {

        e.preventDefault();

        updateRecurringExpenditure(selectedRecurringExpenditureId, selectedRecurringExpenditureData)

        onHide();
    }



    const handleDeleteRecurring = (e) => {

        e.preventDefault();

        deleteRecurringExpenditure(selectedRecurringExpenditureId, selectedRecurringExpenditureData)

        onHide();
    }


    const recurringExpenditureModalClose = () => {

        onHide();
    }

    return (
        <Modal
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редакция на разход: {selectedRecurringExpenditureData.name}
                </Modal.Title>
                <CloseButton onClick={recurringExpenditureModalClose} />
            </Modal.Header>
            <Modal.Body>
                <div className='payment-entries-container'>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Наименование:</Form.Label>
                            <Form.Control name="name" type="text" value={selectedRecurringExpenditureData.name} onChange={handleExpenditureChange}></Form.Control>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Издаване на фактура</Form.Label>
                            <Form.Select name="invoice" value={selectedRecurringExpenditureData.invoice} onChange={handleExpenditureChange}>
                                <option key={1} value={''}>Начин на фактуриране</option>
                                <option key={2} value={'true'}>Фактура</option>
                                <option key={3} value={'false'}>Нефактуриран</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-3" controlId="payment-method">
                            <Form.Label>Метод на плащане</Form.Label>
                            <Form.Select name="method" value={selectedRecurringExpenditureData.method} onChange={handleExpenditureChange}>
                                <option key={1} disabled={true} hidden={true} value={''}>Посочете начин на плащане</option>
                                <option key={2} value={'cash'}>Брой</option>
                                <option key={3} value={'wire'}>Банков превод</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Размер без ДДС:</Form.Label>
                            <Form.Control name="value" type="number" value={selectedRecurringExpenditureData.value} onChange={handleExpenditureChange}></Form.Control>
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <Button onClick={handleDeleteRecurring} variant="danger">Изтриване на разхода</Button>
                </div>
                <div>
                    <Button onClick={handleUpdateRecurringSubmit} variant="warning">Запис</Button>
                    <Button onClick={recurringExpenditureModalClose} variant="secondary">Затвори</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default EditRecurringExpenditureModal;