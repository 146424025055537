import { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { acquireAllRecurringExpenditures, acquireMonthlyExpendituresProAnnum, createMonthlyExpenditures } from "../../services/expenditureService";
import { SingleExpenditure } from "./SingleExpenditure";
import { useNavigate, useParams } from "react-router";
import EditExpenditureModal from "./EditExpenditure";
import NewExpenditureModal from "./NewExpenditure";
import { useAnnum } from "../../contexts/AnnualContext";
import MonthSelector from "../MonthSelector";
import { monthsBG, monthsEN } from "../../common/monthNames";
import { acquirePaymentsInMonthProAnnum } from "../../services/paymentService";
import AdministrationExpenditure from "./recurring/AdministrationExpenditure";



function Expenditures() {

    const params = useParams();
    const navigate = useNavigate();
    const { annum } = useAnnum()


    const [recurringExpendituresCollection, setRecurringExpendituresCollection] = useState('')
    const [monthlyPaymentsCollection, setMonthlyPaymentsCollection] = useState('')

    const [selectedMonth, setSelectedMonth] = useState('')
    const [selectedMonthOrderNumber, setSelectedMonthOrderNumber] = useState('')
    const [titleMonth, setTitleMonth] = useState('')
    const [monthlyExpenditures, setMonthlyExpenditures] = useState('')

    const [showEditExpenditureModal, setShowEditExpenditureModal] = useState(false)
    const [selectedExpenditureId, setSelectedExpenditureId] = useState('')
    const [selectedExpenditureData, setSelectedExpenditureData] = useState('')

    const [showNewExpenditureModal, setShowNewExpenditureModal] = useState(false)




    // Initialize Recurring Expenditures
    useEffect(() => {
        acquireAllRecurringExpenditures(setRecurringExpendituresCollection)
    }, [])

    // Set Selected Month and Acquire the Corresponding Expenditure List
    useEffect(() => {
        setSelectedMonth(params.month)
        const monthIndex = monthsEN.indexOf(params.month)
        setTitleMonth(monthsBG[monthIndex])

        setSelectedMonthOrderNumber(monthIndex + 1)
    }, [params])

    useEffect(() => {
        setMonthlyExpenditures('')
        if (!selectedMonth) {
            setSelectedMonth('january')
            navigate(`/expenditures/january`)
        }
        acquireMonthlyExpendituresProAnnum(annum, selectedMonth, setMonthlyExpenditures)
    }, [selectedMonth, annum])

    // Initialize Initial Expenditures and convert them to monthly expenditures of for a given year
    const handleGenerateRecurrentClick = () => {

        acquireAllRecurringExpenditures(setRecurringExpendituresCollection)

        createMonthlyExpenditures(selectedMonth, annum, recurringExpendituresCollection)
    }

    // Acquire Monthly Paymets Received to form Administaration Reward
    useEffect(() => {
        acquirePaymentsInMonthProAnnum(selectedMonthOrderNumber, annum, setMonthlyPaymentsCollection)
    }, [monthlyExpenditures, selectedMonth, annum])

    const handleEditExpenditureClick = (e) => {
        setSelectedExpenditureId(e.currentTarget.id)

        if (e.target.id == 'name') {
            setShowEditExpenditureModal(true)
        }

    }


    const handleCreateExpenditureClick = () => {

        setShowNewExpenditureModal(true)

    }


    const handleAccessRecurringExpendituresClick = () => {

        navigate('/expenditures-recurring')

    }


    return (
        <>
            <div>
                <div className="month-selector">
                    <MonthSelector />
                </div>
                {selectedMonth && (
                    <div className="month-header">
                        <h5 className="header-title">Разходи: - {annum} - {titleMonth}</h5>
                        <div className='header-button-container'>
                            {!monthlyExpenditures.length > 0 && (
                                <div className="generate-recurrent-container">
                                    <Button onClick={handleGenerateRecurrentClick}>
                                        Зареди месечния списък разходи
                                    </Button>
                                    <Button onClick={handleAccessRecurringExpendituresClick}>
                                        Редактирай периодичните разходи
                                    </Button>
                                </div>
                            )}
                        </div>
                        <hr className="header-line"></hr><hr className="header-line"></hr>
                    </div>
                )}
                {monthlyExpenditures.length > 0 && (
                    <div className="expenditure-container">
                        <Table striped hover variant="light" className="expenditure-table">
                            <thead>
                                <th>Наименование</th>
                                <th>Начин на плащане</th>
                                <th>Фактуриране</th>
                                <th>Номер на фактура</th>
                                <th>Стойност</th>
                                <th>Стойност с ДДС</th>
                                <th>Платени</th>
                                <th>Остатък</th>
                            </thead>
                            <tbody>

                                {monthlyExpenditures.length > 0 && monthlyExpenditures.map((expenditure, index) => {
                                    if (!expenditure.id.includes('Administration'))
                                        return (
                                            <SingleExpenditure index={index}
                                                id={expenditure.id}
                                                data={expenditure.data}
                                                selectedMonth={selectedMonth}
                                                handleEditExpenditureClick={handleEditExpenditureClick}
                                            />
                                        )
                                })}
                                <AdministrationExpenditure
                                    monthlyPaymentsCollection={monthlyPaymentsCollection}
                                    annum={annum}
                                    selectedMonth={selectedMonth}
                                    selectedMonthOrderNumber={selectedMonthOrderNumber}
                                />

                            </tbody>
                            <tfoot>
                                <tr key={-1}>
                                    <td colSpan={'100%'}>
                                        * Редакцията на параметрите на разхода се активира при кликане върху името му
                                    </td>
                                </tr>
                                <tr key={-2}>
                                    <td colSpan={'100%'}>
                                        <Button onClick={handleCreateExpenditureClick}>
                                            Добави нов разход
                                        </Button>
                                        <Button onClick={handleAccessRecurringExpendituresClick}>
                                            Редактирай периодичните разходи
                                        </Button>
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                )}
            </div >
            <NewExpenditureModal
                show={showNewExpenditureModal}
                onHide={() => setShowNewExpenditureModal(false)}
                selectedMonth={selectedMonth}
            />

            {showEditExpenditureModal && (
                <EditExpenditureModal
                    selectedExpenditureId={selectedExpenditureId}
                    selectedExpenditureData={selectedExpenditureData}
                    setSelectedExpenditureData={setSelectedExpenditureData}
                    show={showEditExpenditureModal}
                    onHide={() => setShowEditExpenditureModal(false)}
                />)}
        </>
    );
}

export default Expenditures;