import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { updateSingleExpenditureValue } from "../../services/expenditureService";


export function SingleExpenditure({ index, id, data, selectedMonth, handleEditExpenditureClick }) {

    const [invoiceNo, setInvoiceNo] = useState('')
    const [covered, setCovered] = useState(0)
    const [value, setValue] = useState('')
    const [valueAdded, setValueAdded] = useState('')
    const [outstanding, setOutstanding] = useState(0)

    useEffect(() => {
        setInvoiceNo(data.invoiceNo)
    }, [])


    useEffect(() => {
        setValue(data.value)
    }, [])


    useEffect(() => {
        setCovered(data.covered)
    }, [])


    useEffect(() => {
        if (data.invoice == 'true') {
            setValueAdded((data.value * 1.2).toFixed(2))
        } else {
            setValueAdded(data.value)
        }
    }, [data.value])



    useEffect(() => {
        setOutstanding((value - covered).toFixed(2))
    }, [id, value, covered])



    const handleExpenditureChange = (e) => {

        const updatedKey = e.target.name;
        const updatedValue = e.target.value;

        if (updatedKey == 'invoiceNo') {

            setInvoiceNo(updatedValue)

        } else if (updatedKey == 'covered') {

            setCovered(updatedValue)

        }

        const selectedExpenditureId = e.currentTarget.parentNode.parentNode.id;
        updateSingleExpenditureValue(selectedExpenditureId, updatedKey, updatedValue, selectedMonth)
    }



    return (
        < tr key={index} id={id} onClick={handleEditExpenditureClick}>
            <td id={"name"}>
                {data.name}
            </td>
            <td id={"method"}>
                {(data.method === "wire") ? "Банков превод" : "Брой"}
            </td >
            <td id={"invoice"}>
                {(data.invoice === "true") ? "Фактура" : "Нефактуриран"}
            </td>
            <td id={"invoiceNo"}>
                <Form.Control name="invoiceNo" type="text" value={invoiceNo} onChange={handleExpenditureChange}></Form.Control>
            </td>
            <td id={"value"}>
                {data.value}
            </td>
            <td id={"value-added"}>
                {valueAdded}
            </td>
            <td id="covered">
                <Form.Control name="covered" type="text" value={covered} onChange={handleExpenditureChange}></Form.Control>
            </td>
            <td id={"outstanding"}>
                {outstanding}
            </td>
        </tr >
    )
}