import { auth, db, secondaryApp } from '../firebase-config';
import {
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";




export const login = async (values, setLoading, navigate) => {

    setLoading(true)

    await signInWithEmailAndPassword(auth, values.email, values.password)
        .then(() => {
            setLoading(false)
            navigate("/main")
        })
        .catch(
            (error) => {
                setLoading(false)
                navigate("/")
                console.log(error)
            }
        )
}

export const userSignOut = async () => {
    await signOut(auth).then(() => {
        console.log("User successfully signed out!")

    }).catch(error => console.log(error))
    return
}
