import { useState, useEffect } from "react";
import { useLoading } from "../../contexts/LoadingContext";
import { acquireAllSettlements, acquireSettlementsInMonthProAnnum } from "../../services/settlementService";
import Settlement from "./Settlement";
import MonthSelector from "../MonthSelector";
import { useNavigate, useParams } from "react-router";
import { useAnnum } from "../../contexts/AnnualContext";
import { monthsBG, monthsEN } from "../../common/monthNames";
import { acquireAllExpenditures, acquireMonthlyExpendituresProAnnum } from "../../services/expenditureService";
import { Form, Table } from "react-bootstrap";
import { acquireAllPayments, acquirePaymentsForDealsInMonthProAnnum } from "../../services/paymentService";
import { acquireAllDeals, acquireDealsInMonthProAnnum } from "../../services/dealService";
import { acquireAllDeposits, acquireAllTransfers, acquireMonthlyDepositKM, acquireMonthlyDepositSK, acquireMonthlyTransferKM, acquireMonthlyTransferSK, updateMonthlyDeposit, updateMonthlyTransfer } from "../../services/transfersService";

function Finance() {

    const params = useParams();
    const { annum } = useAnnum();
    const { setLoading } = useLoading();
    const navigate = useNavigate();

    const [selectedMonth, setSelectedMonth] = useState('')
    const [selectedMonthOrderNumber, setSelectedMonthOrderNumber] = useState('')
    const [titleMonth, setTitleMonth] = useState('');

    const [monthlyBankPaymentsReceived, setMonthlyBankPaymentsReceived] = useState(0);
    const [monthlyCashPaymentsReceived, setMonthlyCashPaymentsReceived] = useState(0);
    const [monthlyPaymentsReceived, setMonthlyPaymentsReceived] = useState(0)

    const [totalBankPaymentsReceived, setTotalBankPaymentsReceived] = useState(0);
    const [totalCashPaymentsReceived, setTotalCashPaymentsReceived] = useState(0);
    const [totalPaymentsReceived, setTotalPaymentsReceived] = useState(0)

    const [incurredMonthlyExpenditures, setIncurredMonthlyExpenditures] = useState(0)
    // const [coveredMonthlyWireExpenditures, setCoveredMonthlyWireExpenditures] = useState(0)
    // const [coveredMonthlyCashExpenditures, setCoveredMonthlyCashExpenditures] = useState(0)


    const [coveredMonthlyExpenditures, setCoveredMonthlyExpenditures] = useState(0)
    const [totalExpendituresCovered, setTotalExpendituresCovered] = useState(0)

    const [monthlyExpendituresCollection, setMonthlyExpendituresCollection] = useState(0)
    const [totalExpenditures, setTotalExpenditures] = useState(0)

    const [monthlyRewards, setMonthlyRewards] = useState(0)
    const [monthlyRewardsCovered, setMonthlyRewardsCovered] = useState(0)
    const [totalPendingRewards, setTotalPendingRewards] = useState(0)
    const [totalCoveredRewards, setTotalCoveredRewards] = useState(0)

    const [monthlyRevenue, setMonthlyRevenue] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)

    const [monthlyIncome, setMonthlyIncome] = useState(0)
    const [totalIncome, setTotalIncome] = useState(0)
    const [balanceOverall, setBalanceOverall] = useState('');

    const [monthlyDealsCollection, setMonthlyDealsCollection] = useState('');
    const [dealsCollection, setDealsCollection] = useState('');

    const [monthlyPaymentsCollection, setMonthlyPaymentsCollection] = useState('')
    const [paymentsCollection, setPaymentsCollection] = useState('');

    const [monthlySettlementsCollection, setMonthlySettlementsCollection] = useState('')
    const [settlementsCollection, setSettlementsCollection] = useState('');


    const [expendituresCollection, setExpendituresCollection] = useState('')

    const [stateListener, setStateListener] = useState('')

    const [depositMonthlyKM, setDepositMonthlyKM] = useState('');
    const [depositMonthlySK, setDepositMonthlySK] = useState('');

    const [transferMonthlyKM, setTransferMonthlyKM] = useState('');
    const [transferMonthlySK, setTransferMonthlySK] = useState('');

    const [depositsTotal, setDepositsTotal] = useState('')
    const [transfersTotal, setTransfersTotal] = useState('')

    // Set Selected Month and Acquire the Corresponding Expenditure List
    useEffect(() => {
        setSelectedMonth(params.month)
        const monthIndex = monthsEN.indexOf(params.month)
        setTitleMonth(monthsBG[monthIndex])
        setSelectedMonthOrderNumber(monthIndex + 1)
    }, [params])

    useEffect(() => {
        setMonthlyExpendituresCollection('')
        if (!params.month) {
            setSelectedMonth('january')
            navigate(`/financial-flow/january`)
        }
        acquireMonthlyExpendituresProAnnum(annum, selectedMonth, setMonthlyExpendituresCollection)
    }, [selectedMonth, annum])



    // Calculate incurred / covered monthly expenditures
    useEffect(() => {
        setIncurredMonthlyExpenditures('')
        if (monthlyExpendituresCollection.length > 0) {

            let monthlyIncurredExpenditures = 0;
            let coveredMonthlyExpenditures = 0;
            // let coveredMonthlyWireExpenditures = 0;
            // let coveredMonthlyCashExpenditures = 0;

            monthlyExpendituresCollection.forEach(expenditure => {

                const incurredExpenditure = Number(expenditure.data.value);
                monthlyIncurredExpenditures += incurredExpenditure;

                const coveredExpenditure = Number(expenditure.data.covered);
                // if (expenditure.data.method == "wire") {
                //     coveredMonthlyWireExpenditures += coveredExpenditure
                // } else if (expenditure.data.method == "cash") {
                //     coveredMonthlyCashExpenditures += coveredExpenditure
                // }
                coveredMonthlyExpenditures += coveredExpenditure

            });
            setIncurredMonthlyExpenditures(monthlyIncurredExpenditures)
            // setCoveredMonthlyWireExpenditures(coveredMonthlyWireExpenditures)
            // setCoveredMonthlyCashExpenditures(coveredMonthlyCashExpenditures)
            setCoveredMonthlyExpenditures(coveredMonthlyExpenditures)
        }
    }, [monthlyExpendituresCollection])







    /////////////////////////////////////////////////////////////////////////////////
    //                                                                             //
    //               SETTLEMENTS & EXPENDITURES (INCURRED & COVERED)               //
    //               to serve in overall balance and financial flow                //
    //                                                                             //
    /////////////////////////////////////////////////////////////////////////////////


    // Rewards - Total: Incurred / Covered
    useEffect(() => {
        acquireAllSettlements(setSettlementsCollection)
    }, [stateListener])


    useEffect(() => {
        if (settlementsCollection.length > 0) {

            let totalPendingRewards = 0;
            let totalCoveredRewards = 0;

            settlementsCollection.forEach(settlement => {
                // Incurred Amounts
                const brokerFirstSide = Number(settlement.data.firstSide.brokerReward);
                const brokerSecondSide = Number(settlement.data.secondSide.brokerReward);
                const brokerBankAssistance = Number(settlement.data.bankAssistance.brokerReward);

                const externalFirstSide = Number(settlement.data.firstSide.externalReward);
                const externalSecondSide = Number(settlement.data.secondSide.externalReward);
                const externalBankAssistance = Number(settlement.data.bankAssistance.externalReward);

                // Covered Amounts
                const coveredBrokerFirstSide = Number(settlement.data.firstSide.brokerRewardCovered);
                const coveredBrokerSecondSide = Number(settlement.data.secondSide.brokerRewardCovered);
                const coveredBrokerBankAssistance = Number(settlement.data.bankAssistance.brokerRewardCovered);

                const coveredExternalFirstSide = Number(settlement.data.firstSide.externalRewardCovered);
                const coveredExternalSecondSide = Number(settlement.data.secondSide.externalRewardCovered);
                const coveredExternalBankAssistance = Number(settlement.data.bankAssistance.externalRewardCovered);

                // Derive Totals
                totalPendingRewards += [brokerFirstSide, brokerSecondSide, brokerBankAssistance,
                    externalFirstSide, externalSecondSide, externalBankAssistance].reduce((a, b) => a + b, 0);

                totalCoveredRewards +=
                    [coveredBrokerFirstSide, coveredBrokerSecondSide, coveredBrokerBankAssistance,
                        coveredExternalFirstSide, coveredExternalSecondSide,
                        coveredExternalBankAssistance].reduce((a, b) => a + b, 0);

            })

            setTotalPendingRewards(totalPendingRewards)
            setTotalCoveredRewards(totalCoveredRewards)

        }
    }, [settlementsCollection])





    // Rewards - Monthly: Incurred / Covered

    useEffect(() => {
        setMonthlyRewards(0)
        setMonthlyRewardsCovered(0)

        acquireSettlementsInMonthProAnnum(selectedMonthOrderNumber, annum, setMonthlySettlementsCollection)

    }, [stateListener, selectedMonthOrderNumber, annum])


    useEffect(() => {

        setMonthlyRewards(0)
        setMonthlyRewardsCovered(0)
        if (monthlySettlementsCollection.length > 0) {



            let monthlyRewards = 0;
            let monthlyCoveredRewards = 0;

            monthlySettlementsCollection.forEach(settlement => {
                // Incurred Amounts
                const brokerFirstSide = Number(settlement.data.firstSide.brokerReward);
                const brokerSecondSide = Number(settlement.data.secondSide.brokerReward);
                const brokerBankAssistance = Number(settlement.data.bankAssistance.brokerReward);

                const externalFirstSide = Number(settlement.data.firstSide.externalReward);
                const externalSecondSide = Number(settlement.data.secondSide.externalReward);
                const externalBankAssistance = Number(settlement.data.bankAssistance.externalReward);

                // Covered Amounts
                const coveredBrokerFirstSide = Number(settlement.data.firstSide.brokerRewardCovered);
                const coveredBrokerSecondSide = Number(settlement.data.secondSide.brokerRewardCovered);
                const coveredBrokerBankAssistance = Number(settlement.data.bankAssistance.brokerRewardCovered);

                const coveredExternalFirstSide = Number(settlement.data.firstSide.externalRewardCovered);
                const coveredExternalSecondSide = Number(settlement.data.secondSide.externalRewardCovered);
                const coveredExternalBankAssistance = Number(settlement.data.bankAssistance.externalRewardCovered);

                // Derive Totals
                monthlyRewards += [brokerFirstSide, brokerSecondSide, brokerBankAssistance,
                    externalFirstSide, externalSecondSide, externalBankAssistance].reduce((a, b) => a + b, 0);

                monthlyCoveredRewards +=
                    [coveredBrokerFirstSide, coveredBrokerSecondSide, coveredBrokerBankAssistance,
                        coveredExternalFirstSide, coveredExternalSecondSide,
                        coveredExternalBankAssistance].reduce((a, b) => a + b, 0);

            })

            setMonthlyRewards(monthlyRewards)
            setMonthlyRewardsCovered(monthlyCoveredRewards)

        }
    }, [selectedMonthOrderNumber, monthlySettlementsCollection])





    // Expenditures - Total: Incurred / Covered
    useEffect(() => {
        acquireAllExpenditures(setExpendituresCollection)
    }, [])

    useEffect(() => {
        if (expendituresCollection.length > 0) {

            let totalExpenditures = 0;
            let totalExpendituresCovered = 0;

            expendituresCollection.forEach(expenditure => {

                const amount = Number(expenditure.data.value);

                const covered = Number(expenditure.data.covered);

                totalExpenditures += amount

                totalExpendituresCovered += covered

            })
            setTotalExpenditures(totalExpenditures)
            setTotalExpendituresCovered(totalExpendituresCovered)
        }
    }, [expendituresCollection])






    /////////////////////////////////////////////////////////////////////////////////
    //                                                                             //
    //                          TOTAL BALANCE ESTIMATES                            //
    //                                                                             //
    /////////////////////////////////////////////////////////////////////////////////

    // Income - Total 

    useEffect(() => {
        acquireAllDeals(setDealsCollection, setLoading);
    }, [])

    useEffect(() => {
        if (dealsCollection.length > 0) {
            let totalRevenue = 0;
            dealsCollection.forEach(deal => {
                const dealTotalRevenue = Number(deal.data.totalRevenue);
                totalRevenue += dealTotalRevenue;
            })
            setTotalRevenue(totalRevenue);
        }
    }, [dealsCollection])


    // Income - Monthly 
    useEffect(() => {
        setMonthlyRevenue(0);
        acquireDealsInMonthProAnnum(selectedMonthOrderNumber, annum, setMonthlyDealsCollection);
    }, [selectedMonthOrderNumber, annum])

    useEffect(() => {
        if (monthlyDealsCollection.length > 0) {
            setMonthlyRevenue('');
            let monthlyRevenue = 0;
            monthlyDealsCollection.forEach(deal => {
                const dealTotalRevenue = Number(deal.data.totalRevenue);
                monthlyRevenue += dealTotalRevenue;
            })
            setMonthlyRevenue(monthlyRevenue);
        }
    }, [monthlyDealsCollection])



    // Total Income Estimation
    useEffect(() => {
        const totalIncome = totalRevenue - (totalExpenditures + totalPendingRewards)
        setTotalIncome(totalIncome)
    }, [totalRevenue, totalExpenditures, totalPendingRewards])


    // Revenue - Monthly
    useEffect(() => {
        //   const monthlyIncome = monthlyRevenue - (monthlyIncurredExpenditures + monthl
    })


    /////////////////////////////////////////////////////////////////////////////////
    //                                                                             //
    //                          FINANCIAL FLOW ESTIMATES                           //
    //                                                                             //
    /////////////////////////////////////////////////////////////////////////////////


    // Payments Received - Total
    useEffect(() => {
        acquireAllPayments(setPaymentsCollection, setLoading);
    }, [])

    useEffect(() => {
        if (paymentsCollection.length > 0) {

            let totalBankPaymentsReceived = 0;
            let totalCashPaymentsReceived = 0;
            let totalPaymentsReceived = 0;

            paymentsCollection.forEach(receivedPayment => {

                const amount = Number(receivedPayment.data.value);

                if (receivedPayment.data.method == "wire") {
                    totalBankPaymentsReceived += amount;

                } else if (receivedPayment.data.method == "cash") {
                    totalCashPaymentsReceived += amount;
                }

                totalPaymentsReceived = totalPaymentsReceived + amount;

            })
            setTotalBankPaymentsReceived(totalBankPaymentsReceived)
            setTotalCashPaymentsReceived(totalCashPaymentsReceived)
            setTotalPaymentsReceived(totalPaymentsReceived)
        }
    }, [paymentsCollection])



    // Payments Received - Monthly
    useEffect(() => {
        setMonthlyBankPaymentsReceived(0)
        setMonthlyCashPaymentsReceived(0)
        setMonthlyPaymentsReceived(0)
        acquirePaymentsForDealsInMonthProAnnum(selectedMonthOrderNumber, annum, setMonthlyPaymentsCollection)
    }, [selectedMonthOrderNumber, annum])



    useEffect(() => {
        if (monthlyPaymentsCollection.length > 0) {

            let monthlyBankPaymentsReceived = 0;
            let monthlyCashPaymentsReceived = 0;
            let monthlyPaymentsReceived = 0;

            monthlyPaymentsCollection.forEach(receivedPayment => {

                const amount = Number(receivedPayment.data.value);

                if (receivedPayment.data.method == "wire") {
                    monthlyBankPaymentsReceived += amount;

                } else if (receivedPayment.data.method == "cash") {
                    monthlyCashPaymentsReceived += amount;
                }

                monthlyPaymentsReceived += amount;

            })
            setMonthlyBankPaymentsReceived(monthlyBankPaymentsReceived)
            setMonthlyCashPaymentsReceived(monthlyCashPaymentsReceived)
            setMonthlyPaymentsReceived(monthlyPaymentsReceived)
        }
    }, [monthlyPaymentsCollection])


    // Initialize Monthly Deposits 
    useEffect(() => {
        setDepositMonthlyKM('')
        setDepositMonthlySK('')
        acquireMonthlyDepositKM("KM", selectedMonthOrderNumber, annum, setDepositMonthlyKM)
        acquireMonthlyDepositSK("SK", selectedMonthOrderNumber, annum, setDepositMonthlySK)
    }, [selectedMonthOrderNumber, annum])

    // Initialize Monthly Transfers
    useEffect(() => {
        setTransferMonthlyKM('')
        setTransferMonthlySK('')
        acquireMonthlyTransferKM("KM", selectedMonthOrderNumber, annum, setTransferMonthlyKM)
        acquireMonthlyTransferSK("SK", selectedMonthOrderNumber, annum, setTransferMonthlySK)
    }, [selectedMonthOrderNumber, annum,])


    // Monthly Change Handlers

    // Deposits
    const handleDepositsChange = (e) => {

        const shareholderInitials = e.target.id
        let amount = Number(e.target.value)

        if (e.target.value == 0) {
            amount = ''
        }

        if (shareholderInitials === "KM") {
            setDepositMonthlyKM(amount)
        } else if (shareholderInitials === "SK") {
            setDepositMonthlySK(amount)
        }
        const deposit = {
            month: selectedMonth,
            year: annum,
            shareholder: shareholderInitials,
            value: amount
        }

        updateMonthlyDeposit(shareholderInitials, selectedMonthOrderNumber, annum, deposit)
    }

    // Transfers
    const handleTransfersChange = (e) => {

        const shareholderInitials = e.target.id
        let amount = Number(e.target.value)

        if (e.target.value == 0) {
            amount = ''
        }

        if (shareholderInitials === "KM") {
            setTransferMonthlyKM(amount)
        } else if (shareholderInitials === "SK") {
            setTransferMonthlySK(amount)
        }


        const transfer = {
            month: selectedMonth,
            year: annum,
            shareholder: shareholderInitials,
            value: amount
        }

        updateMonthlyTransfer(shareholderInitials, selectedMonthOrderNumber, annum, transfer)
    }




    useEffect(() => {

        let balanceOverall = 0;

        acquireAllDeposits(setDepositsTotal)

        acquireAllTransfers(setTransfersTotal)

        balanceOverall =
            Number(totalPaymentsReceived)
            - Number(totalExpendituresCovered)
            - Number(totalCoveredRewards)
            + Number(depositsTotal)
            - Number(transfersTotal);

        setBalanceOverall(balanceOverall.toFixed(2))

    }, [totalBankPaymentsReceived, totalExpendituresCovered, totalCoveredRewards, depositsTotal, transfersTotal])




    return (
        <div>


            <div className="month-selector">
                <MonthSelector />
            </div>
            {selectedMonth && (
                <>
                    <div className="monthly-summary-container">
                        <h5 className="header-title">Финанси: - {annum} - {titleMonth}</h5>
                        <div>
                            <Table bordered>
                                <tr key={1}>
                                    <th key={1}>Фирмени: {(monthlyRevenue).toFixed(2)} лв.</th>
                                    <th key={2}>Възнаграждения: {(monthlyRewards).toFixed(2)} лв.</th>
                                    <th key={3}>Разходи: {Number(incurredMonthlyExpenditures).toFixed(2)} лв.</th>
                                    <th key={4}>Внесени К. М. :
                                        <Form.Control name="deposit-KM"
                                            id={'KM'}
                                            type="number"
                                            placeholder='Внесени'
                                            value={depositMonthlyKM}
                                            onChange={handleDepositsChange} />
                                    </th>
                                    <th key={5}>Реализирани К. М. :
                                        <Form.Control name="transfer-KM"
                                            id={'KM'}
                                            type="number"
                                            placeholder='Реализирани'
                                            value={transferMonthlyKM}
                                            onChange={handleTransfersChange}
                                        />
                                    </th>
                                    <th key={6}>Наличност:</th>
                                </tr>
                                <tr key={2}>
                                    <th key={1}>Очаквани: {(monthlyRevenue - monthlyPaymentsReceived).toFixed(2)} лв.</th>
                                    <th key={2}>Изплатени: {(monthlyRewardsCovered).toFixed(2)} лв.</th>
                                    <th key={3}>Платени: {(coveredMonthlyExpenditures).toFixed(2)} лв.</th>
                                    <th key={4}>Внесени С. К. :
                                        <Form.Control name="deposit-KM"
                                            id={'SK'}
                                            type="number"
                                            placeholder='Внесени'
                                            value={depositMonthlySK}
                                            onChange={handleDepositsChange} />
                                    </th>
                                    <th key={5}>Реализирани С. К. :
                                        <Form.Control name="transfer-SK"
                                            id={'SK'}
                                            type="number"
                                            placeholder='Реализирани'
                                            value={transferMonthlySK}
                                            onChange={handleTransfersChange} />
                                    </th>
                                    <th key={6}>{balanceOverall}</th>
                                </tr>
                            </Table>
                        </div>
                    </div>

                </>
            )}
            <div className="summary-container">
                <Table bordered>
                    <tr key={1}>
                        <th key={1}>Фирмени: {totalRevenue.toFixed(2)} лв.</th>
                        <th key={2}>Възнаграждения: {totalPendingRewards} лв.</th>
                        <th key={3}>Разходи: {totalExpenditures.toFixed(2)} лв.</th>
                    </tr>
                    <tr key={2}>
                        <th key={1}>Очаквани: {(totalRevenue - totalPaymentsReceived).toFixed(2)} лв.</th>
                        <th key={2}>Изплатени: {totalCoveredRewards.toFixed(2)} лв.</th>
                        <th key={3}>Платени: {totalExpendituresCovered.toFixed(2)} лв.</th>
                    </tr>
                </Table>
            </div>
            <div className='settlement-list-container'>
                {monthlySettlementsCollection.length > 0 && Object.values(monthlySettlementsCollection).map((settlement, index) => (
                    <Settlement settlement={settlement} stateListener={stateListener} setStateListener={setStateListener} />
                ))}
            </div >
        </div >
    )
}

export default Finance;