import { useState } from 'react';
import { CloseButton, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useLoading } from '../../contexts/LoadingContext';
import { useAnnum } from '../../contexts/AnnualContext';
import { createNewSecurity } from '../../services/securitiesService';


const NewSecurityModal = ({ show, onHide, selectedMonth }) => {

    const { annum } = useAnnum()
    const { setLoading } = useLoading();

    const initialSecurityState = {
        name: '',
        month: '',
        value: '',
        covered: '',
    };

    const [newSecurity, setNewSecurityState] = useState(initialSecurityState)


    const handleSecurityChange = (e) => {
        setNewSecurityState(state => ({ ...state, [e.target.name]: e.target.value }))
    }




    const handleCreateSecuritySubmit = (e) => {

        e.preventDefault();

        createNewSecurity(newSecurity, annum, selectedMonth, setLoading)

        setNewSecurityState(initialSecurityState)

        onHide();
    }



    const createSecurityModalClose = () => {

        setNewSecurityState(initialSecurityState)

        onHide();
    }

    return (
        <Modal
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Добави осигуряващо се лице: {''}
                </Modal.Title>
                <CloseButton onClick={createSecurityModalClose} />
            </Modal.Header>
            <Modal.Body>
                <div className='security-entries-container'>
                    <div className='security-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Име:</Form.Label>
                            <Form.Control name="name" type="text" value={newSecurity.name} onChange={handleSecurityChange}></Form.Control>
                        </Form.Group>
                    </div>
                    <div className='security-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Осигурителна вноска:</Form.Label>
                            <Form.Control name="value" type="number" value={newSecurity.value} onChange={handleSecurityChange}></Form.Control>
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <Button onClick={handleCreateSecuritySubmit} variant="warning">Запис</Button>
                    <Button onClick={createSecurityModalClose} variant="secondary">Затвори</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default NewSecurityModal;