import { useEffect, useState } from 'react';
import { CloseButton, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { acquireSelectedSecurity, deleteSelectedSecurity, updateSelectedSecurity } from '../../services/securitiesService';


const EditSecurityModal = ({ selectedSecurityId, selectedSecurityData, setSelectedSecurityData, show, onHide }) => {

    useEffect(() => {

        acquireSelectedSecurity(selectedSecurityId, selectedSecurityData, setSelectedSecurityData)

    }, [selectedSecurityId])





    const handleSecurityChange = (e) => {

        setSelectedSecurityData(state => ({ ...state, [e.target.name]: e.target.value }))
    }




    const handleUpdateSecuritySubmit = (e) => {

        e.preventDefault();

        updateSelectedSecurity(selectedSecurityId, selectedSecurityData)

        onHide();
    }



    const editSecurityModalClose = () => {

        onHide();
    }


    const handleDeleteSelected = (e) => {

        e.preventDefault();

        deleteSelectedSecurity(selectedSecurityId)

        onHide();
    }

    return (
        <Modal
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редакция на осигуряващо се лице: {''}
                </Modal.Title>
                <CloseButton onClick={editSecurityModalClose} />
            </Modal.Header>
            {selectedSecurityData && (<Modal.Body>
                <div className='security-entries-container'>
                    <div className='security-entry-item'>
                        <Form.Group className="mb-3" controlId="payment-value" >
                            <Form.Label>Име:</Form.Label>
                            <Form.Control name="name" type="text" value={selectedSecurityData.name} onChange={handleSecurityChange}></Form.Control>
                        </Form.Group>
                    </div>
                    <div className='security-entry-item'>
                        <Form.Group className="mb-3" controlId="payment-value" >
                            <Form.Label>Осигурителна вноска BGN:</Form.Label>
                            <Form.Control name="value" type="number" value={selectedSecurityData.value} onChange={handleSecurityChange}></Form.Control>
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>)}
            <Modal.Footer>
                <div>
                    <Button onClick={handleDeleteSelected} variant="danger">Изтриване</Button>
                </div>
                <div>
                    <Button onClick={handleUpdateSecuritySubmit} variant="warning">Запис</Button>
                    <Button onClick={editSecurityModalClose} variant="secondary">Затвори</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )

}

export default EditSecurityModal;