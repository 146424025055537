import { db } from "../firebase-config"


export async function createNewPayment(payment) {
    await db.collection('payments').add(payment)
        .then(newPayment => {
            //           db.collection('payments').doc(newPayment).update({ ...values, ['uid']: newPayment })
        })
        .catch(error => {
            console.log(error)
        });
}


// To be used when assigning payments made for deals of a particular month
export async function acquirePaymentsForDealsInMonthProAnnum(selectedMonthOrderNumber, annum, setMonthlyPaymentsCollection) {

    const monthlyDealsId = []
    db.collection('deals').onSnapshot(dealsCollection => {
        dealsCollection.forEach(databaseDeal => {

            // Distil deal year & month order number from html date input string in format yyyy-mm-dd
            const dealYear = Number(databaseDeal.data().date.split('-')[0])
            const dealMonthOrder = Number(databaseDeal.data().date.split('-')[1])

            if (dealMonthOrder == selectedMonthOrderNumber && dealYear == annum) {
                const dealId = databaseDeal.id

                monthlyDealsId.push(dealId)
            }
        })
    }, error => {
        console.log(`Encountered error: ${error}`);
    });


    db.collection('payments').onSnapshot(paymentsCollection => {
        const databasePayments = []
        paymentsCollection.forEach(databasePayment => {
            const paymentDealId = databasePayment.data().dealId

            if (monthlyDealsId.includes(paymentDealId)) {
                const payment = {
                    id: databasePayment.id,
                    data: databasePayment.data()
                }
                databasePayments.push(payment)

            }
        })
        setMonthlyPaymentsCollection(databasePayments)

    }, error => {
        console.log(`Encountered error: ${error}`);
    });
}




// To be used when forming monthly administartion reward
export async function acquirePaymentsInMonthProAnnum(selectedMonthOrderNumber, annum, setMonthlyPaymentsCollection) {

    db.collection('payments').onSnapshot(paymentsCollection => {

        const databasePayments = []
        paymentsCollection.forEach(databasePayment => {

            // Distil deal year & month order number from html date input string in format yyyy-mm-dd
            const paymentYear = Number(databasePayment.data().date.split('-')[0])
            const paymentMonthOrder = Number(databasePayment.data().date.split('-')[1])

            if (paymentMonthOrder == selectedMonthOrderNumber && paymentYear == annum) {
                const payment = {
                    id: databasePayment.id,
                    data: databasePayment.data()
                }

                databasePayments.push(payment)
            }
        })

        setMonthlyPaymentsCollection(databasePayments)

    }, error => {
        console.log(`Encountered error: ${error}`);
    });
}



export async function acquireRelevantPayments(dealId, setRelevantPayments, setLoading) {
    setLoading(true);
    const relevantPayments = [];
    const snapshot = await db.collection('payments').where('dealId', '==', dealId).get()
        .catch((error) => {
            console.log(error)
        })

    snapshot.forEach(entry => {
        const payment = {
            id: entry.id,
            data: entry.data()
        }
        relevantPayments.push(payment)
    })
    setRelevantPayments(relevantPayments);
    setLoading(false)
}


export async function acquireSelectedPayment(selectedPaymentId, setPaymentState, setLoading) {
    setLoading(true);
    db.collection('payments').doc(selectedPaymentId).get().then(firestorePayment => {
        setPaymentState(firestorePayment.data())
        setLoading(false)
    }).catch((error) => {
        console.log(error)
    })
}






export async function acquireAllPayments(setPaymentsCollection, setLoading) {
    setLoading(true)

    db.collection('payments').onSnapshot(paymentCollection => {
        const firestorePayments = []
        paymentCollection.forEach(firestorePayment => {
            const payment = {
                id: firestorePayment.id,
                data: firestorePayment.data()
            }
            firestorePayments.push(payment)
        })
        setPaymentsCollection(firestorePayments)
    })
    setLoading(false)
}




export async function setAdditionalPayment(dealId, additionalRevenuePayment, setLoading) {
    setLoading(true)

    await db.collection('payments').where('dealId', '==', dealId).get().then(result => {

        let existingId = ''

        result.forEach(payment => {
            if (payment.data().role == 'Допълнителен') {
                existingId = payment.id;
                console.log(payment.data())
                db.collection('payments').doc(existingId).set(additionalRevenuePayment)
            }
        })
        if (!existingId) {

            db.collection('payments').add(additionalRevenuePayment)
        }
    })
}



export async function setPayment(selectedPaymentId, paymentState, setLoading) {
    setLoading(true);
    await db.collection('payments').doc(selectedPaymentId).set(paymentState)
        .catch((error) => {
            console.log(error)
        })
    setLoading(false)
}

export async function deletePayment(selectedPaymentId, setLoading) {
    setLoading(true);
    await db.collection('payments').doc(selectedPaymentId).delete()
        .catch((error) => {
            console.log(error)
        })
    setLoading(false)
}


export async function deleteDealPayments(deletedDealId) {

    await db.collection('payments').where('dealId', '==', deletedDealId).get()
        .then(relatedPayments => {
            relatedPayments.forEach(relatedPayment => {
                const relatedPaymentId = relatedPayment.id
                db.collection('payments').doc(relatedPaymentId).delete()

            })
        })
}
