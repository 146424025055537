import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { useEffect, useState } from 'react';
import { useLoading } from '../../../contexts/LoadingContext';


import { acquireDeal, deleteDeal, updateDeal } from '../../../services/dealService';
import { useNavigate, useParams } from 'react-router';

import UpdateClientInfo from './UpdateClientInfo';
import UpdateBankInfo from './UpdateBankInfo';
import { acquireRelevantPayments, setAdditionalPayment, setPayment } from '../../../services/paymentService';
import { Table } from 'react-bootstrap';
import UpdatePaymentModal from '../../payments/UpdatePayment';



const UpdateDeal = function () {


    const { dealId } = useParams();
    const { setLoading } = useLoading();
    const navigate = useNavigate()

    const [selectedId, setSelectedDealId] = useState('');
    const [selectedData, setSelectedDealData] = useState('');

    const euroBGN = 1.95583;
    const initialSideState = {
        represented: '',
        info: {},
    };
    const initialBankState = {
        status: '',
        amount: '',
        info: {},
    };
    const initialRevenueState = {
        source: '',
        value: 0,
    };
    const initialDealDataStructure = {
        dealType: '',
        date: '',
        dealNumber: '',
        priceEUR: '',
        priceBGN: '',
        clients: '',
        firstSide: initialSideState,
        secondSide: initialSideState,
        bankAssistance: initialBankState,
        additionalRevenue: initialRevenueState,
        totalAdditionalRevenue: '',
        totalRevenue: '',
        notes: '',
    };



    const [dealType, setDealType] = useState('');
    const [date, setDate] = useState('');
    const [dealNumber, setNumber] = useState('');
    const [priceEUR, setPriceEUR] = useState(0);
    const [priceBGN, setPriceBGN] = useState(0);
    const [clients, setClients] = useState('');
    const [firstSide, setFirstSide] = useState('');
    const [secondSide, setSecondSide] = useState('');
    const [bankAssistance, setBankAssistance] = useState('');
    const [additionalRevenue, setAdditionalRevenue] = useState('');
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [notes, setNote] = useState('');
    const [newDealInfo, setNewDealInfo] = useState('');
    const [bankAssistanceStatus, setBankAssistanceStatus] = useState('')

    const [dealRoles, setDealRoles] = useState('')

    const [relevantPayments, setRelevantPayments] = useState('');
    const [selectedPaymentId, setSelectedPaymentId] = useState('');
    const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false);



    // Acquire Deal
    useEffect(() => {
        acquireDeal(dealId, setSelectedDealId, setSelectedDealData, setLoading);
    }, [dealId])


    // Acquire Relevant Payments
    useEffect(() => {
        acquireRelevantPayments(dealId, setRelevantPayments, setLoading);
    }, [dealId, showUpdatePaymentModal])


    useEffect(() => {
        setDealType(selectedData.dealType)
    }, [dealId, selectedData.dealType])

    useEffect(() => {
        setDate(selectedData.date)
    }, [dealId, selectedData.date])

    useEffect(() => {
        setNumber(selectedData.dealNumber)
    }, [dealId, selectedData.dealNumber])

    useEffect(() => {
        setPriceEUR(selectedData.priceEUR)
    }, [dealId, selectedData.priceEUR])

    useEffect(() => {
        setPriceBGN(selectedData.priceBGN)
    }, [dealId, selectedData.priceBGN])


    useEffect(() => {
        setClients(selectedData.clients)
    }, [dealId, selectedData.clients])


    useEffect(() => {
        setFirstSide(selectedData.firstSide)
    }, [selectedData.firstSide])

    useEffect(() => {
        setSecondSide(selectedData.secondSide)
    }, [selectedData.secondSide])

    /// Bank Assistance
    useEffect(() => {
        if (selectedData) {
            setBankAssistance(selectedData.bankAssistance)
        }
    }, [dealId])

    useEffect(() => {
        if (!bankAssistanceStatus) {
            setBankAssistance(state => ({ ...state, status: false }))
            setBankAssistance(state => ({ ...state, amount: 0 }))
            setBankAssistance(state => ({ ...state, info: {} }))
        }
    }, [bankAssistanceStatus])


    useEffect(() => {
        if (selectedData) {
            setBankAssistanceStatus(selectedData.bankAssistance.status)
        }
    }, [dealId, selectedData])

    useEffect(() => {
        if (selectedData) {
            setAdditionalRevenue(selectedData.additionalRevenue)
        }
    }, [dealId, selectedData])

    useEffect(() => {
        setTotalRevenue(selectedData.totalRevenue)
    }, [dealId])

    useEffect(() => {
        setNote(selectedData.notes)
    }, [dealId, selectedData.notes])

    useEffect(() => {
        setNewDealInfo(selectedData.newDealInfo)
    }, [dealId, selectedData.newDealInfo])



    const handleDealTypeChange = (e) => {
        setDealType(e.target.value);
        setClients('')

    };

    const handleDateSelect = (e) => {
        setDate(e.target.value);
    };

    const handleClientsChange = (e) => {
        setClients(e.target.value);
    };

    const handleNoteEntry = (e) => {
        setNote(e.target.value);
    };

    const changePriceEUR = (e) => {
        const priceEUR = e.target.value
        setPriceEUR(priceEUR);
        calculatePriceBGN(priceEUR);

    };

    const calculatePriceBGN = (priceEUR) => {
        const priceBGN = (priceEUR * euroBGN).toFixed(2);
        setPriceBGN(priceBGN);
    };

    const toggleBankAssistanceStatus = () => {
        setBankAssistance(state => ({ ...state, status: !bankAssistance.status }))
        setBankAssistanceStatus(!bankAssistanceStatus)
    }

    const handleRevenueEntry = (e) => {
        setAdditionalRevenue(revenue => ({ ...revenue, [e.target.name]: e.target.value }))

    };


    const handleUpdatePaymentClick = (e) => {
        setSelectedPaymentId(e.target.parentNode.id);
        setShowUpdatePaymentModal(true);
    }


    // UPDATE
    const handleUpdateDealSubmit = (e) => {
        e.preventDefault()
        const updatedDeal = {
            id: dealId,
            data: newDealInfo
        }

        // if additional revenue value is entered
        const additionalRevenuePayment = {
            dealId: dealId,
            date: date,
            role: 'Допълнителен',
            method: 'cash',
            value: additionalRevenue.value
        };
        setAdditionalPayment(dealId, additionalRevenuePayment, setLoading)

        updateDeal(updatedDeal)
        navigate('/deals')
    }


    const handleDeleteClick = () => {
        deleteDeal(dealId)
        navigate('/deals')
    }

    ////////////////////////////////////////////////////////////

    // Submit
    useEffect(() => {
        // Update Deal 
        const dealDataStructure = {
            dealType: dealType,
            date: date,
            dealNumber: dealNumber,
            priceEUR: priceEUR,
            priceBGN: priceBGN,
            clients: clients,
            firstSide: firstSide,
            secondSide: secondSide,
            bankAssistance: bankAssistance,
            additionalRevenue: additionalRevenue,
            totalRevenue: totalRevenue,
            notes: notes,
        };
        setNewDealInfo(dealDataStructure)

    }, [dealId, dealType, date, dealNumber, priceEUR, clients,
        firstSide, secondSide, bankAssistance, bankAssistanceStatus,
        additionalRevenue, totalRevenue, notes]);

    ////////////////////////////////////////////////////////////


    // Update Payments


    // Type
    useEffect(() => {
        setFirstSide(initialSideState);
        setSecondSide(initialSideState);
        setBankAssistance(initialBankState);
    }, [dealType]);

    // Clients
    useEffect(() => {

        if (clients === 'tenant') {
            setFirstSide(firstSide => ({ ...firstSide, represented: 'Наемател' }));
            setSecondSide(initialSideState);
            setBankAssistance(initialBankState);

        } else if (clients === 'landlord') {
            setFirstSide(firstSide => ({ ...firstSide, represented: 'Наемодател' }));
            setSecondSide(initialSideState);
            setBankAssistance(initialBankState);

        } else if (clients === 'doubleRentalDeal') {
            setFirstSide(firstSide => ({ ...firstSide, represented: 'Наемател' }));
            setSecondSide(secondSide => ({ ...secondSide, represented: 'Наемодател' }));
            setBankAssistance(initialBankState);

        } else if (clients === 'buyer') {
            setFirstSide(firstSide => ({ ...firstSide, represented: 'Купувач' }));
            setSecondSide(initialSideState);

        } else if (clients === 'seller') {
            setFirstSide(firstSide => ({ ...firstSide, represented: 'Продавач' }));
            setSecondSide(initialSideState);

        } else if (clients === 'doublePurchaseDeal') {
            setFirstSide(firstSide => ({ ...firstSide, represented: 'Купувач' }));
            setSecondSide(secondSide => ({ ...secondSide, represented: 'Продавач' }));
        }
    }, [dealId, clients, dealType]);




    // Total Revenue (NB: To include total additional revenue in future updates)
    useEffect(() => {
        if (firstSide && secondSide && bankAssistance && additionalRevenue) {
            const firstSideRevenue = Number(firstSide.info.revenue)
            const secondSideRevenue = Number(secondSide.info.revenue)
            const bankAssistanceRevenue = Number(bankAssistance.info.revenue)
            const additional = Number(additionalRevenue.value)
            let currentTotalRevenue = 0;
            [firstSideRevenue, secondSideRevenue, bankAssistanceRevenue, additional].forEach(addend => {
                if (addend) {
                    currentTotalRevenue += addend;
                }
            });
            setTotalRevenue(Number(currentTotalRevenue).toFixed(2));
        }

    }, [firstSide, secondSide, bankAssistance, bankAssistanceStatus, additionalRevenue])


    // Participants
    useEffect(() => {
        const dealRoles = [];
        if (firstSide) {
            dealRoles.push(firstSide.represented);
        }
        if (secondSide.represented) {
            dealRoles.push(secondSide.represented);
        }
        if (bankAssistance.amount > 0) {
            dealRoles.push('Банка');
        }
        setDealRoles(dealRoles);
    }, [dealId, clients, bankAssistance.amount])


    return (
        <Form>

            <div className="edit-deal-container" >
                <div className='edit-fullspan-container'>
                    <Form.Group className="mb-3" controlId="notes">
                        <Form.Label><b>Сделка № : {selectedData.dealNumber}</b></Form.Label>
                    </Form.Group>
                </div>

                <div className='grid-container'>
                    <div className='grid-item'>
                        <Form.Group className="mb-3" controlId="form-deal-type">
                            <Form.Label>Основна информация</Form.Label>
                        </Form.Group>
                        <hr></hr>

                        <Form.Group className="mb-3" controlId="form-deal-type">
                            <Form.Label>Вид сделка</Form.Label>
                            <Form.Select
                                aria-label="Select deal type"
                                name="type"
                                value={dealType}
                                onChange={handleDealTypeChange}
                                disabled={true}
                            >
                                <option value="" disabled={true} hidden={true} >Посочете вида на сделката</option>
                                <option value="purchaseDeal">Продажба</option>
                                <option value="rentalDeal">Отдаване под наем</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="form-deal-value">
                            <Form.Label>Дата на сключване</Form.Label>
                            <Form.Control
                                type="date" name="date"
                                placeholder="Псочете дата на сключване"
                                value={date}
                                onChange={handleDateSelect}
                                disabled={true}
                            />
                        </Form.Group>

                        {dealType === "rentalDeal" && (
                            <Form.Group className="mb-3" controlId="form-side" >
                                <Form.Label>Представителство</Form.Label>
                                <Form.Select
                                    aria-label="Select deal side"
                                    name="clients"
                                    defaultValue={selectedData.clients}
                                    onChange={handleClientsChange}
                                    disabled={true}>
                                    <option value="" disabled={true} hidden={true}>Посочете представляваната страна</option>
                                    <option value="landlord">Наемодател</option>
                                    <option value="tenant">Наемател</option>
                                    <option value="doubleRentalDeal">Двете страни</option>
                                </Form.Select>
                            </Form.Group>
                        )}

                        {dealType === "purchaseDeal" && (
                            <Form.Group className="mb-3" controlId="form-side">
                                <Form.Label>Представителство</Form.Label>
                                <Form.Select
                                    aria-label="Select deal side"
                                    name="clients"
                                    defaultValue={selectedData.clients}
                                    onChange={handleClientsChange}
                                    disabled={true}>
                                    <option value="" disabled={true} hidden={true}>Посочете представляваната страна</option>
                                    <option value="seller">Продавач</option>
                                    <option value="buyer">Купувач</option>
                                    <option value="doublePurchaseDeal">Двете страни</option>
                                </Form.Select>
                            </Form.Group>
                        )}

                        {(
                            <div><span></span>
                                <table className='price-table'>
                                    <thead></thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Form.Group className="mb-3" controlId="form-deal-value-EUR">
                                                    <Form.Label>Стойност EUR</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="priceEUR"
                                                        placeholder="Въведете стойност (EUR)"
                                                        value={priceEUR}
                                                        onChange={changePriceEUR} />
                                                </Form.Group>

                                            </td>
                                            <td>
                                                <Form.Group className="mb-3" controlId="form-deal-value-BGN">
                                                    <Form.Label>Стойност BGN</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="priceBGN"
                                                        value={priceBGN}
                                                        disabled={true} />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}

                        <Form.Group className="mb-3" controlId="notes">
                            <Form.Label>Бележки</Form.Label>
                            <Form.Control as="textarea" rows={4} value={notes} onChange={handleNoteEntry} />
                        </Form.Group>
                        <hr></hr>

                    </div>

                    <div className='grid-item'>
                        <Form.Group className="mb-3" controlId="form-deal-type">
                            <Form.Label>Първа страна</Form.Label>
                        </Form.Group>

                        <hr></hr>

                        {((selectedData.firstSide && selectedData.firstSide.represented) || (firstSide.represented && firstSide.info)) && (
                            <UpdateClientInfo
                                priceBGN={priceBGN}
                                selectedSide={firstSide}
                                originalInfo={selectedData.firstSide.info}
                                setSideInfo={setFirstSide}
                                setPriceBGN={setPriceBGN}
                            />
                        )}
                    </div>
                    <div className='grid-item'>
                        <Form.Group className="mb-3" controlId="form-deal-type">
                            <Form.Label>Втора страна</Form.Label>
                        </Form.Group>
                        <hr></hr>
                        {((selectedData.secondSide && selectedData.secondSide.represented) && (['doubleRentalDeal', 'doublePurchaseDeal'].includes(clients))) && (
                            <UpdateClientInfo
                                priceBGN={priceBGN}
                                selectedSide={secondSide}
                                originalInfo={selectedData.secondSide.info}
                                setSideInfo={setSecondSide}
                                setPriceBGN={setPriceBGN}
                            />
                        )}
                    </div>
                </div>
                <div className='edit-fullspan-container-left'>

                    {selectedData.bankAssistance && (selectedData.dealType == 'purchaseDeal' || dealType == 'purchaseDeal') && (
                        <div>
                            <hr></hr>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Банков кредит"
                                checked={bankAssistanceStatus}
                                onChange={toggleBankAssistanceStatus}
                            />
                            <hr></hr>
                        </div>
                    )}


                    {selectedData.bankAssistance && bankAssistanceStatus && (
                        <div >

                            <UpdateBankInfo
                                selectedSide={selectedData.bankAssistance}
                                selectedSideInfo={selectedData.bankAssistance.info}
                                bankAssistanceStatus={bankAssistanceStatus}
                                setSideInfo={setBankAssistance}
                            />

                        </div >
                    )}

                    <div >
                        {selectedData.additionalRevenue && (
                            <Form.Group className="mb-3" controlId="additional-revenue">
                                <table className='additional-revenue-table'>
                                    <thead><Form.Label>Допълнителен приход</Form.Label></thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Form.Group className="mb-1" controlId="notes-title">
                                                    <Form.Control name="source" type="text" placeholder='Тип приход' value={additionalRevenue.source} onChange={handleRevenueEntry} />
                                                </Form.Group>
                                            </td>
                                            <td>
                                                <Form.Group className="mb-1" controlId="notes-title">
                                                    <Form.Control name="value" type="number" placeholder='Размер BGN' value={additionalRevenue.value} onChange={handleRevenueEntry} />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Form.Group>
                        )}

                    </div >

                    <div>
                        <Form.Group className="mb-1" controlId="notes-title">
                            <Form.Control name="totalRevenue" type="text" value={`Фирмени: ${totalRevenue} BGN`} disabled={true} />
                        </Form.Group>
                    </div>

                    {/* Payments */}
                    <div>
                        {relevantPayments.length > 0 && (
                            <div>
                                <br></br>
                                <Form.Group className="mb-1" controlId="additional-revenue">
                                    <Form.Label>Регистрирани плащания</Form.Label>
                                </Form.Group>
                                <Table striped bordered hover variant="light">
                                    <thead>
                                        <tr>
                                            <th>Сума</th>
                                            <th>Дата</th>
                                            <th>Платец</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {relevantPayments.map((payment, index) => (
                                            <tr id={payment.id} key={index} disabled={true} onClick={handleUpdatePaymentClick}>
                                                <td>
                                                    {`${payment.data.value} лв.`}
                                                </td>
                                                <td>
                                                    {payment.data.date}
                                                </td>
                                                <td>
                                                    {payment.data.role}
                                                </td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        )
                        }
                    </div>

                    {showUpdatePaymentModal && (
                        <UpdatePaymentModal
                            dealId={dealId}
                            dealRoles={dealRoles}
                            dealNumber={dealNumber}
                            selectedPaymentId={selectedPaymentId}
                            setSelectedPaymentId={setSelectedPaymentId}
                            setLoading={setLoading}
                            show={showUpdatePaymentModal}
                            onHide={() => setShowUpdatePaymentModal(false)}
                        />
                    )}

                    <div className='edit-deal-button-container'>
                        <Button variant="primary" onClick={handleUpdateDealSubmit}>
                            Запис
                        </Button>
                        <Button variant="danger" onClick={handleDeleteClick}>
                            Изтриване
                        </Button>
                    </div >
                </div>
            </div >
        </Form >
    );
};

export default UpdateDeal;