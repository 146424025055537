import { useState } from 'react';
import { CloseButton, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { createNewExpenditure, } from '../../services/expenditureService';
import { useLoading } from '../../contexts/LoadingContext';
import { useAnnum } from '../../contexts/AnnualContext';


const NewExpenditureModal = ({ show, onHide, selectedMonth }) => {

    const { annum } = useAnnum()
    const { setLoading } = useLoading();

    const initialExpenditureState = {
        name: '',
        invoice: '',
        invoiceNo: '',
        method: '',
        value: '',
        covered: '',
    };

    const [newExpenditure, setNewExpenditureState] = useState(initialExpenditureState)


    const handleExpenditureChange = (e) => {
        setNewExpenditureState(state => ({ ...state, [e.target.name]: e.target.value }))
    }




    const handleCreateExpenditureSubmit = (e) => {

        e.preventDefault();

        createNewExpenditure(newExpenditure, annum, selectedMonth, setLoading)

        setNewExpenditureState(initialExpenditureState)

        onHide();
    }



    const createExpenditureModalClose = () => {

        setNewExpenditureState(initialExpenditureState)

        onHide();
    }

    return (
        <Modal
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редакция на разход: {''}
                </Modal.Title>
                <CloseButton onClick={createExpenditureModalClose} />
            </Modal.Header>
            <Modal.Body>
                <div className='payment-entries-container'>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Наименование:</Form.Label>
                            <Form.Control name="name" type="text" value={newExpenditure.name} onChange={handleExpenditureChange}></Form.Control>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Фактуриране</Form.Label>
                            <Form.Select name="invoice" value={newExpenditure.invoice} onChange={handleExpenditureChange}>
                                <option key={1} value={''}>Фактуриране</option>
                                <option key={2} value={'true'}>Фактура</option>
                                <option key={3} value={'false'}>Нефактуриран</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-3" controlId="payment-method">
                            <Form.Label>Начин на плащане</Form.Label>
                            <Form.Select name="method" value={newExpenditure.method} onChange={handleExpenditureChange}>
                                <option key={1} disabled={true} hidden={true} value={''}>Посочете начин на плащане</option>
                                <option key={2} value={'cash'}>Брой</option>
                                <option key={3} value={'wire'}>Банков превод</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Размер без ДДС:</Form.Label>
                            <Form.Control name="value" type="number" value={newExpenditure.value} onChange={handleExpenditureChange}></Form.Control>
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <Button onClick={handleCreateExpenditureSubmit} variant="warning">Запис</Button>
                    <Button onClick={createExpenditureModalClose} variant="secondary">Затвори</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default NewExpenditureModal;