import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

import { userSignOut } from '../services/authService';
import { Link } from 'react-router-dom';
import logo from '../Jivena-Real-Estate-Agency-Logo.png';
import AnnumSelector from './AnnumSelector';


const Navigation = function () {

    const { userToken } = useContext(AuthContext)

    return (
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand><img src={logo} className='logo' /></Navbar.Brand>
                {/* <Navbar.Brand>Jivena Console</Navbar.Brand> */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">

                        {userToken && (
                            <>
                                <div className="navigation-menu">
                                    <Nav.Link>
                                        <Link className="navigation-link" to="/financial-flow">Финанси</Link>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <Link className="navigation-link" to="/deals">Сделки</Link>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <Link className="navigation-link" to="/expenditures">Разходи</Link>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <Link className="navigation-link" to="/securities">Осигуровки</Link>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <Link className="navigation-link" onClick={userSignOut}>Изход</Link>
                                    </Nav.Link>
                                    <div className='annum-selector'>
                                        <AnnumSelector />
                                    </div>
                                </div>
                            </>
                        )}
                        {!userToken && (
                            <div id="navigation-menu">

                            </div>
                        )}
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    {userToken && (
                        <Navbar.Text>
                            Профил: <b>{`${userToken.email}`}</b>
                        </Navbar.Text>
                    )}
                </Navbar.Collapse>
            </Container >
        </Navbar >
    );
};

export default Navigation;