
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { brokerList } from '../../../resources/brokerList';

const UpdateClientInfo = ({ priceBGN, selectedSide, originalInfo, setSideInfo }) => {

    const [offerNo, setOfferNumber] = useState('');
    const [broker, setBroker] = useState('');
    const [commissionPercentage, setCommissionPercentage] = useState('');
    const [commissionManual, setCommissionManual] = useState('');
    const [commission, setCommission] = useState('');
    const [externalRewardStatus, setExternalRewardStatus] = useState('');
    const [externalRewardPercentage, setExternalRewardPercentage] = useState('');
    const [externalRewardManual, setExternalRewardManual] = useState('');
    const [externalReward, setExternalReward] = useState('');
    const [brokerRewardPercentage, setBrokerRewardPercentage] = useState('');
    const [brokerRewardManual, setBrokerRewardManual] = useState('');
    const [brokerReward, setBrokerReward] = useState('');
    const [revenue, setRevenue] = useState('');

    const handleBrokerSelect = (e) => {
        setBroker(e.target.value)
    }

    const handleOfferNumberChange = (e) => {
        setOfferNumber(e.target.value)
    }

    const changeCommissionPercentage = (e) => {
        setCommissionPercentage(e.target.value)
    }

    const handleCommissionManualChange = (e) => {
        setCommissionManual(e.target.value)
    }

    function toggleExternalRewardStatus() {
        setExternalRewardStatus(!externalRewardStatus)
    }

    const changeExternalRewardPercentage = (e) => {
        setExternalRewardPercentage(e.target.value)
    }

    const handleExternalRewardManualChange = (e) => {
        setExternalRewardManual(e.target.value)
    }

    const changeBrokerRewardPercentage = (e) => {
        setBrokerRewardPercentage(e.target.value)
    }

    const handleBrokerRewardManualChange = (e) => {
        setBrokerRewardManual(e.target.value)
    }

    // Offer No
    useEffect(() => {
        setOfferNumber(originalInfo.offerNo)
    }, [])

    // Broker
    useEffect(() => {
        setBroker(originalInfo.broker)
    }, [])


    // Commission Percentage
    useEffect(() => {
        setCommissionPercentage(originalInfo.commissionPercentage)
    }, [])

    // Commission Manual
    useEffect(() => {
        setCommissionManual(originalInfo.commissionManual)
    }, [])

    // Commission
    useEffect(() => {
        calculateCommision()
    }, [priceBGN, commissionManual, commissionPercentage])


    // External Reward Status
    useEffect(() => {
        if (originalInfo.externalReward) {
            setExternalRewardStatus(true)
        }

    }, [])

    // Toggle External Reward
    useEffect(() => {
        if (!externalRewardStatus) {
            setExternalRewardManual('');
            setExternalRewardPercentage('');
            setBrokerReward('');
        }
    }, [externalRewardStatus])


    // External Percentage
    useEffect(() => {
        setExternalRewardPercentage(originalInfo.externalRewardPercentage)
    }, [])

    // External Manual
    useEffect(() => {
        setExternalRewardManual(originalInfo.externalRewardManual)
    }, [])

    // External Reward
    useEffect(() => {
        calculateExternalReward()
    }, [commission, externalRewardManual, externalRewardPercentage])


    // Broker Reward Percentage
    useEffect(() => {
        setBrokerRewardPercentage(originalInfo.brokerRewardPercentage)
    }, [])

    // Broker Reward Manual
    useEffect(() => {
        setBrokerRewardManual(originalInfo.brokerRewardManual)
    }, [])

    // Broker Reward
    useEffect(() => {
        calculateBrokerReward()
    }, [commission, externalReward, brokerRewardManual, brokerRewardPercentage])

    // Revenue
    useEffect(() => {
        calculateRevenue()
    }, [commission, externalReward, brokerReward])


    // Updade Client Info in Parent Component State
    useEffect(() => {
        setSideInfo(side => ({
            ...side, info: {
                offerNo: offerNo,
                broker: broker,
                commissionPercentage: commissionPercentage,
                commissionManual: commissionManual,
                commission: commission,
                externalRewardPercentage: externalRewardPercentage,
                externalRewardManual: externalRewardManual,
                externalReward: externalReward,
                brokerRewardPercentage: brokerRewardPercentage,
                brokerRewardManual: brokerRewardManual,
                brokerReward: brokerReward,
                revenue: revenue,
            }
        }))
    }, [offerNo, broker, commission, externalReward, brokerReward, revenue])


    function calculateCommision() {

        if (commissionManual > 0) {
            setCommission(commissionManual)

        } else if (commissionPercentage > 0 && commissionManual == 0) {
            const currentCommission = Number((commissionPercentage / 100) * priceBGN).toFixed(2)
            setCommission(currentCommission)

        } else {
            setCommission(0)
        }
    }


    function calculateExternalReward() {

        if (externalRewardManual > 0) {
            setExternalReward(externalRewardManual)

        } else if (externalRewardPercentage > 0 && externalRewardManual == 0) {
            const currentExternalReward = Number((externalRewardPercentage / 100) * commission).toFixed(2)
            setExternalReward(currentExternalReward)

        } else {
            setExternalReward(0)
        }
    }

    function calculateBrokerReward() {

        if (brokerRewardManual > 0) {
            setBrokerReward(brokerRewardManual)

        } else if (brokerRewardPercentage > 0 && brokerRewardManual == 0) {
            const currentBrokerReward = Number((brokerRewardPercentage / 100) * (commission - externalReward)).toFixed(2)
            setBrokerReward(currentBrokerReward)

        } else {
            setBrokerReward(0)
        }
    }

    function calculateRevenue() {
        const currentRevenue = commission - externalReward - brokerReward
        setRevenue(currentRevenue)
    }


    return (
        <div>

            <Form.Label><b>{selectedSide.represented}</b></Form.Label>


            <Form.Group className="mb-3" controlId="form-deal-broker">
                <Form.Label>Представляващ брокер</Form.Label>
                <Form.Select name="broker" onChange={handleBrokerSelect}>
                    <option key={-1} value={originalInfo.broker} disabled={true} hidden={true} selected={true}>{originalInfo.broker}</option>
                    {brokerList.map((broker, index) =>
                        <option key={index}>{broker}</option>)
                    }
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="form-deal-number">
                <Form.Label>Номер оферта</Form.Label>
                <Form.Control type="number" name="offerNo" placeholder="Оферта №" value={offerNo} onChange={handleOfferNumberChange} disabled={true} />
            </Form.Group>

            <Form.Group className="mb-1" controlId="form-deal-commission-percentage">
                <Form.Label>Комисионна</Form.Label>
                <Form.Control
                    type="number" name="commissionPercentage" min="0" max="100" placeholder="Комисионна %"
                    value={commissionPercentage}
                    onChange={changeCommissionPercentage}
                />
            </Form.Group>
            <Form.Group className="mb-1" controlId="form-deal-commission-manual">
                <Form.Control
                    type="number" name="commissionManual" min="0" placeholder="Въведете комисионна BGN"
                    value={commissionManual}
                    onChange={handleCommissionManualChange}
                />
            </Form.Group>
            <Form.Group className="mb-1" controlId="form-deal-commission">
                <Form.Control
                    type="text" name="commission"
                    value={`Комисионна: ${commission} BGN`}
                    disabled={true}
                />
            </Form.Group>


            {commission !== 0 && (
                <Form.Group className="mb-1" controlId="form-deal-external">
                    <br></br>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Агентски"
                        checked={externalRewardStatus}
                        onChange={toggleExternalRewardStatus}
                    />
                </Form.Group>
            )
            }

            {commission !== 0 && externalRewardStatus && (
                <div>
                    <Form.Group className="mb-1" controlId="form-deal-external-percentage">
                        <Form.Control type="number" name="externalRewardPercentage" min="0" max="100" placeholder="Агентски %" value={externalRewardPercentage} onChange={changeExternalRewardPercentage} />
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="form-deal-external-manual">
                        <Form.Control type="number" name="externalRewardManual" min="0" placeholder="Въведете агентски BGN" value={externalRewardManual} onChange={handleExternalRewardManualChange} />
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="form-deal-external">
                        <Form.Control type="text" name="externalReward" value={`Агентски: ${externalReward} BGN`} disabled={true} />
                    </Form.Group>
                </div>
            )
            }

            {commission !== 0 && (
                <div>
                    <br></br>
                    <Form.Group className="mb-1" controlId="form-deal-external">
                        <Form.Label>Брокерско възнаграждение</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="form-deal-external">
                        <Form.Control type="number" name="brokerRewardPercentage" min="0" max="100" placeholder="Брокерско възнаграждение %" value={brokerRewardPercentage} onChange={changeBrokerRewardPercentage} />
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="form-deal-external">
                        <Form.Control type="number" name="brokerRewardManual" min="0" placeholder="Въведете брокерско възнаграждение BGN" value={brokerRewardManual} onChange={handleBrokerRewardManualChange} />
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="form-deal-external">
                        <Form.Control type="text" name="brokerReward" value={`Брокерско възнаграждение: ${brokerReward} BGN`} disabled={true} />
                    </Form.Group>
                </div>
            )
            }

        </div >
    )
}

export default UpdateClientInfo;