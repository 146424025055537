
import { useState } from 'react';
import { CloseButton, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { createNewRecurringExpenditure } from '../../../services/expenditureService';


const NewRecurringExpenditureModal = ({ show, onHide }) => {

    const initialRecurringExpenditureState = {
        name: '',
        invoice: '',
        invoiceNo: '',
        method: '',
        value: '',
        covered: '',
    };

    const [recurringExpenditure, setRecurringExpenditureState] = useState(initialRecurringExpenditureState)

    const handleExpenditureChange = (e) => {
        setRecurringExpenditureState(state => ({ ...state, [e.target.name]: e.target.value }))
    }


    const handleCreateRecurringSubmit = (e) => {
        e.preventDefault();

        createNewRecurringExpenditure(recurringExpenditure)

        setRecurringExpenditureState(initialRecurringExpenditureState)

        onHide();
    }


    const recurringExpenditureModalClose = () => {

        setRecurringExpenditureState(initialRecurringExpenditureState)

        onHide();
    }

    return (
        <Modal
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Нов разход:
                </Modal.Title>
                <CloseButton onClick={recurringExpenditureModalClose} />
            </Modal.Header>
            <Modal.Body>
                <div className='payment-entries-container'>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Наименование:</Form.Label>
                            <Form.Control name="name" type="text" onChange={handleExpenditureChange}></Form.Control>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Издаване на фактура</Form.Label>
                            <Form.Select name="invoice" defaultValue={''} onChange={handleExpenditureChange}>
                                <option key={1} value={''}>Начин на фактуриране</option>
                                <option key={2} value={'true'}>Фактура</option>
                                <option key={3} value={'false'}>Нефактуриран</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-3" controlId="payment-method">
                            <Form.Label>Метод на плащане</Form.Label>
                            <Form.Select name="method" defaultValue={''} onChange={handleExpenditureChange}>
                                <option key={1} disabled={true} hidden={true} value={''}>Посочете начин на плащане</option>
                                <option key={2} value={'cash'}>Брой</option>
                                <option key={3} value={'wire'}>Банков превод</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='payment-entry-item'>
                        <Form.Group className="mb-1" controlId="payment-value" >
                            <Form.Label>Размер без ДДС:</Form.Label>
                            <Form.Control name="value" type="number" onChange={handleExpenditureChange}></Form.Control>
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCreateRecurringSubmit} variant="primary">Запиши новия разход</Button>
                <Button onClick={recurringExpenditureModalClose} variant="secondary">Затвори</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NewRecurringExpenditureModal;